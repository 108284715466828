import React from 'react';
import Modal from "../../componentes/Modal.js";
import Notify from '../Notify';
import {
    BotonLetrasVerdeOscuro
} from "../Plantillas/Botones";
import MovimientosCaja from "../Caja/MovimientosCaja";
import TurnoApertura from "../Caja/TurnoApertura";
import {loadLogoByRUC} from "../../helpers/funcionesGlobales";


class AperturarTurnosMovcaja extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            modalIsOpen: false,
            IdCaja: 0,
            MontoInicial: 0,
            loading: true,
            error: null,
            extImg: 'png',
            IdTurno: 0,
            hasTurno: false,
            infoCajas: [],
        }
        this.verificarTurno = this.verificarTurno.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleClickNuevo = this.handleClickNuevo.bind(this);
    }

    componentDidMount = async () => {
        this.setState({loading: true})
        await this.verificarTurno();
        await this.getInfoCajas();
        this.setState({loading: false})
    }

    async verificarTurno() {
        try {
            const response = await fetch(`/api/cajas/verificar/Turno`);
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    extImg: data.extImg,
                    IdCaja: data.Turno[0][0].IdCaja,
                    MontoInicial: Number(data.Turno[0][0].MontoInicial),
                    IdTurno: data.Turno[0][0].IdTurnoCaja,
                    hasTurno: data.Turno[0].length > 0,
                })

            }
        } catch (error) {
            this.setState({error: error})
        }
    }

    async getInfoCajas() {
        try {
            const response = await fetch(`/api/cajas/info-sucursal`);
            if (!response.ok) {
                throw new Error()
            }

            const cajas = await response.json();
            this.setState({ infoCajas: cajas });

        } catch (error) {
            this.setState({ infoCajas: [] })
        }
    }

    handleCloseModal = () => {
        this.setState({
            modalIsOpen: false,
            Confirmacion: false
        })
    }

    handleClickNuevo = async () => {
        try {
            const response = await fetch(`/api/cajas/user/validar`);
            const data = await response.json();
            this.setState({
                modalIsOpen: true
            })
        } catch (error) {
            this.setState({loading: false, error: error})
            return Notify("No tienes permiso para aperturar caja. Revisar la configuración de tu rol", "warning", 2500)
        }
    }

    render() {
        if (this.state.hasTurno)
            return <MovimientosCaja montoInicial={this.state.MontoInicial} extImg={this.state.extImg}
                                    idTurno={this.state.IdTurno}
                                    idCaja={this.state.IdCaja}/>
        return (
            <div style={{marginTop:"200px"}}>
                <div className="preventa__container2">
                    <div className="container">
                        <div className='mt-5 instruccion-caja' style={{textAlign: 'justify'}}>
                        Antes de usar la caja, por favor ingrese la cantidad exacta de dinero que hay en ella. Esto será utilizado como fondo inicial de apertura. También puede añadir una observación opcional. La caja permanecerá cerrada hasta que realice esta acción. ¡Gracias!
                        </div>
                        <BotonLetrasVerdeOscuro className="mt-5 d-flex justify-content-center mt-2 aperturar-caja ml-auto mr-auto"
                                                onClick={this.handleClickNuevo}>Aperturar Caja</BotonLetrasVerdeOscuro>
                        { !!this.state.infoCajas.length && <div className='row mt-3 justify-content-center '>
                            <div className='col-8'>
                                <table className='table table-hover'>
                                    <thead>
                                        <tr>
                                           <th colSpan={3} className='text-center'>Cajas activas</th> 
                                        </tr>
                                        <tr>
                                            <th>Caja</th>
                                            <th>Usuario</th>
                                            <th>Fecha y hora</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.infoCajas.map(c => {
                                                return <tr>
                                                    <td>{ c.Nombre }</td>
                                                    <td>{ c.Usuario }</td>
                                                    <td>{ c.FechaInicio }</td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div> }
                    </div>
                </div>
                <Modal
                    isOpen={this.state.modalIsOpen} onClose={this.handleCloseModal} title="APERTURAR CAJA">
                    <TurnoApertura/>
                </Modal>
            </div>
        )
    }
}

export default AperturarTurnosMovcaja;
