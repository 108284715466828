import React from 'react';
import SelectSearch from "react-select-search";
import "../../pages/styles/SelectSearch.css";
import "../styles/Caja.css";
import Notify from '../Notify';
import Modal from "../../componentes/Modal.js";
import RegistroVentaList from "./RegistrosVenta.js"
import "react-moment";
import moment from "moment";
import {BotonCrearLetras, BotonCancelar, BotonAddDoc} from "../Plantillas/Botones";
import PageLoading from '../PageLoading';
import VehiculoAddForm from "../Vehiculos/VehiculoAddForm";
import AddTransportistaForm from "../Transportistas/AddTransportistaForm";
import {BOLETA, DNI, FACTURA, NOTA_EGRESO, ORDEN_COMPRA, RUC, VALE, MOTIVO_TRASLADO, decimalAdjust} from "../../Global";
import {notificarError} from "../Almacenes/AlmacenNotify";
import {EmpresaTransportistaAddForm} from '../EmpresasTransportistas/EmpresaTransportistaAddForm';


class GuiaRemision extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            destinatario: {},
            Hoy: '',
            dataSucursal: [],
            dataMotivos: {
                Motivos: []
            },
            IdAlmacen: '',
            IdAlmacenLlegada: '',
            dataAlmacenes: {
                Almacenes: []
            },
            IdEmpresaTransportista: null,
            IdSucursal: "",
            ComentarioAdicional: "",
            IdEmpresa: "",
            Ruc: "",
            RazonSocial: "",
            Sucursal: "",
            Direccion: "",
            DireccionDeSalida: "",
            DireccionDeLlegada: "",
            FechaDeEmision: null,
            FechaDeInicioDeTraslado: "",
            IdMotivoDeTraslado: 1,
            DescripcionMotivoTraslado: "",
            TipoDocDestinatario: "RUC",
            NumeroDocDestinatario: "",
            RazonSocialDestinatario: "",
            destinatarioDisabled: false,
            IdTransportista: null,
            IdCoTransportista: null,
            HabilitarCoPiloto: false,
            Serie: '',
            NumeroComprobante: '',
            numYCorrelativoRV: '',
            IdUbigeoSalida: "",
            RegionSalida: "",
            ProvinciaSalida: "",
            DistritoSalida: "",
            dataUbigeos: {
                results: []
            },
            dataregSalida: [],
            dataprovSalida: [],
            datadistSalida: [],
            IdUbiLlegada: "",
            RegionLlegada: "",
            ProvinciaLlegada: "",
            DistritoLlegada: "",
            dataregLlegada: [],
            dataprovLlegada: [],
            datadistLlegada: [],

            dataVehiculos: {
                Vehiculos: []
            },
            dataEmpresasTransportistas: [],
            dataTransportistas: {
                Transportistas: []
            },
            IdUbigeoLlegada: "",
            dataCoTransportistas: [],

            statusModal: false,


            ventas: [],
            Venta: [],
            DetallesVenta: [],

            DetallesVentaNuevo: [],
            IdRegistroVentas: null,
            isVale: false,
            isOrdenCompra: false,
            IdVales: null,
            Mes: null,
            Year: null,
            Peso: 0,
            IdGuiaRemision: '',
            datatipoDocumento: {
                tipoDocumento: []
            },
            OrdenCompra: {
                IdTipoDocumentoSunat: '',
                Serie: '',
                Correlativo: ''
            },
            IdTipoDocumentoSunat: '',
            ventasBF: [],
            loading: false,
            error: null,
            numContenedor: null,
            numBultos: null,
            isOpenModalVehiculo: false,
            isOpenModalEmpresaTransportista: false,
            isEmpleado: false,
            isOpenModalTrans: false,
        }
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleOnCloseModal = this.handleOnCloseModal.bind(this)
        this.gerRegistrosVentaPorTurnos = this.gerRegistrosVentaPorTurnos.bind(this)
        this.gerValesPorTurnos = this.gerValesPorTurnos.bind(this)
        this.obtenerRV = this.obtenerRV.bind(this)
        this.getUbigeoByCodigoUbigeo = this.getUbigeoByCodigoUbigeo.bind(this)
        this.cargarUbigeoAlmacen = this.cargarUbigeoAlmacen.bind(this)
        this.onSaveVehiculo = this.onSaveVehiculo.bind(this)
        this.onSaveTransportista = this.onSaveTransportista.bind(this)
    }

    componentDidMount = async () => {
        this.getInfoSucursal();
        const Fecha = new Date();
        this.setState({
            Mes: Fecha.getMonth(),
            Year: Fecha.getFullYear(),
            FechaDeEmision: moment(Fecha).format('YYYY-MM-DD'),
            FechaDeInicioDeTraslado: moment(Fecha).format('YYYY-MM-DD'),
            Hoy: moment(Fecha).format('YYYY-MM-DD')
        });
        await Promise.all([
            this.listaUbigeos(),
            this.obtenerAlmacenes(),
            this.getTipoDocSunat(),
            this.getVehiculos(),
            this.getTransportistas(),
            this.getMotivosDeTraslado(),
            this.getEmpresasTransportistas()
        ])
    }

    getTipoDocSunat = async () => {
        // this.setState({loading:true, error:null})
        try {
            const response = await fetch(`/api/guiaRemision/tipoDoc`);
            const data = await response.json();
            this.setState({
                // loading: false,
                datatipoDocumento: data
            })
            // console.log(data)
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }

    handleOrdenCompraTipoDocChange = async (e) => {
        this.setState({
            OrdenCompra: {
                IdTipoDocumentoSunat: e.value,
            }
        })
    }

    handleTipoDocSunatChange = async (e) => {

        this.setState({
            Venta: [],
            DetallesVenta: [],
            DetallesVentaNuevo: [],
            IdRegistroVentas: '',
            IdVale: '',
            TipoDocDestinatario: '',
            DireccionDeLlegada: '',
            NumeroDocDestinatario: '',
            RazonSocialDestinatario: '',
            destinatarioDisabled: true,
            numYCorrelativoRV: '',
            IdTipoDocumentoSunat: e.value,
        })

        if (String(e.value) === String(BOLETA) || String(e.value) === String(FACTURA))
            await this.gerRegistrosVentaPorTurnos(this.state.Mes, this.state.Year);
        else if (String(e.value) === String(NOTA_EGRESO))
            await this.getNotasEgreso(this.state.Mes, this.state.Year, e.value);
        else if (String(e.value) === String(VALE))
            await this.gerValesPorTurnos(this.state.Mes, this.state.Year);

        else if (String(e.value) === String(ORDEN_COMPRA)) {
            await this.gerOrdenesCompra()
            this.setState({
                IdAlmacen: this.state.IdSucursal
            })
        }
    }

    async getNotasEgreso(month, year, tipoDoc) {
        const res = await fetch(`/api/guiaRemision/notas?month=${month}&year=${year}&tipoDoc=${tipoDoc}`);
        const dataRes = await res.json();
        this.setState({
            ventas: dataRes
        })
    }

    handleChangeDocOrdenCompra = (e) => {
        this.setState({
            OrdenCompra: {
                ...this.state.OrdenCompra,
                [e.target.name]: e.target.value
            }
        })
    }

    ListaTipoDocSunat = (TipoDocumentoSunat, ables = []) => {
        let availables = [...TipoDocumentoSunat];

        if (ables.length > 0) {
            availables = TipoDocumentoSunat.filter(doc => ables.includes(doc.IdTipoDocumentoSunat))
        }

        return availables.map(Documento => {
            //console.log(producto)
            return {
                title: Documento.NombreDoc == "Otros" ? "Vales " + "(" + Documento.NombreDoc.toLowerCase() + ")" : Documento.NombreDoc,
                name: Documento.DescripcionCorta == "Otros" ? "Vales " + "(" + Documento.DescripcionCorta.toLowerCase() + ")" : Documento.DescripcionCorta,
                value: Documento.IdTipoDocumentoSunat
            };
        });
    }

    obtenerAlmacenes = async () => {
        // this.setState({loading:true, error:null})
        try {
            const response = await fetch(`/api/guiaRemision/AlmacenesEmpresa`);
            const data = await response.json();
            this.setState({
                // loading: false,
                dataAlmacenes: data
            })
            // console.log(data)
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }

    obtenerRV = (IdRegistroVentas, data) => {
        console.log({data})
        // console.log(IdRegistroVentas)
        this.handleOnCloseModal()
        const isVale = data.IdVale ? true : false
        if (isVale) {
            //console.log("es vale")
            this.ObtenerVale(data.IdVale);
            this.ObtenerDetalleVale(data.IdVale)
            return;
        }

        if ('IdOrdenCompra' in data) {
            this.ObtenerOrdenCompra(data.IdOrdenCompra);
            this.ObtenerOrdenCompraDetalles(data.IdOrdenCompra);
            return;
        }
        //console.log("es otro")
        this.ObtenerVenta(IdRegistroVentas, data)
        this.ObtenerDetalleVenta(IdRegistroVentas, data)
    }

    ObtenerVale = async (IdVales) => {
        await fetch(`/api/vales/get/${IdVales}`)
            .then(response => response.json())
            .then(async Venta => {
                    this.setState({
                        Venta: Venta.respuesta[0],
                        IdRegistroVentas: Venta.respuesta[0].IdVale,
                        TipoDocDestinatario: Venta.respuesta[0],
                        NumeroDocDestinatario: Venta.respuesta[0].NroTipoDocumento,
                        RazonSocialDestinatario: Venta.respuesta[0].RazonSocial,
                        isLoading: false,
                        isVale: true,
                        destinatarioDisabled: true,
                        numYCorrelativoRV: Venta.respuesta[0].Serie.concat(" - ", Venta.respuesta[0].NumeroComprobante),
                        DireccionDeLlegada: Venta.respuesta[0].DireccionCliente,
                    })
                    await this.getUbigeoByCodigoUbigeo(Venta.respuesta[0].Ubigeo)
                    // console.log(this.state.numYCorrelativoRV)
                }
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    ObtenerDetalleVale = async (IdVale) => {
        fetch(`/api/vales/detalles/${IdVale}`)
            .then(response => response.json())
            .then(async DetallesVenta => {

                    await this.ObtenerIdAlmacenPorIdStock(DetallesVenta.respuesta[0].IdStock)
                    this.setState({
                        DetallesVentaNuevo: [],
                        DetallesVenta: DetallesVenta.respuesta,
                        isLoading: false
                    })

                    let detReg = DetallesVenta.respuesta.map(dp => ({
                        ...dp,
                        Peso: 0,
                        Observacion: ''
                    }))

                    this.setState({
                        DetallesVentaNuevo: detReg
                    })
                }
            )
            .catch(error => this.setState({error, isLoading: false}));

    }

    ObtenerOrdenCompra = async (IdOrdenCompra) => {
        await fetch(`/api/ordenescompra/item/${IdOrdenCompra}`)
            .then(response => response.json())
            .then(async response => {
                    const ordenCompra = response[0][0];
                    this.setState({
                        Venta: ordenCompra,
                        IdRegistroVentas: ordenCompra.IdOrdenCompra,
                        TipoDocDestinatario: RUC,
                        NumeroDocDestinatario: ordenCompra.RucProveedor,
                        RazonSocialDestinatario: ordenCompra.NombreProveedor,
                        isLoading: false,
                        isOrdenCompra: true,
                        destinatarioDisabled: true,
                        numYCorrelativoRV: ordenCompra.Numero,
                        DireccionDeLlegada: ordenCompra.DireccionProveedor,
                    })
                    await this.getUbigeoByCodigoUbigeo(ordenCompra.UbigeoProveedor)
                }
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    ObtenerOrdenCompraDetalles = async (IdOrdenCompra) => {
        await fetch(`/api/ordenescompra/detalleoc/${IdOrdenCompra}`)
            .then(response => response.json())
            .then(async response => {
                const detalles = response[1];

                this.setState({
                    DetallesVentaNuevo: [],
                    DetallesVenta: detalles,
                    isLoading: false
                })

                let detReg = detalles.map(d => ({
                    ...d,
                    IdDetalleVenta: d.IdDetalleOC,
                    NombreProducto: d.NomProducto,
                    PrecioVenta: d.PrecioReferencial,
                    Importe: decimalAdjust('floor', d.PrecioReferencial * d.Cantidad, -2),
                    Peso: 0,
                    Observacion: ''
                }))

                this.setState({
                    DetallesVentaNuevo: detReg
                })
            }
        )
        .catch(error => this.setState({error, isLoading: false}));
    }


    ObtenerVenta = async (IdRegistroVentas, data) => {
        if (String(this.state.IdTipoDocumentoSunat) === String(NOTA_EGRESO)) {
            await this.setState({
                Venta: data,
                IdRegistroVentas: data.IdRegistroVenta,
                isLoading: false,
                destinatarioDisabled: false,
                numYCorrelativoRV: data.numSerie
            })
        } else if (String(this.state.IdTipoDocumentoSunat) === String(BOLETA) || String(this.state.IdTipoDocumentoSunat) === String(FACTURA)) {
            await fetch(`/api/ventas/get/${IdRegistroVentas}`)
                .then(response => response.json())
                .then(async Venta => {
                        this.setState({
                            Venta: Venta.respuesta[0],
                            IdRegistroVentas: Venta.respuesta[0].IdRegistroVenta,
                            TipoDocDestinatario: Venta.respuesta[0].CodigoDocumentoCliente,
                            NumeroDocDestinatario: Venta.respuesta[0].NroTipoDocumento,
                            RazonSocialDestinatario: Venta.respuesta[0].RazonSocial,
                            isLoading: false,
                            destinatarioDisabled: true,
                            numYCorrelativoRV: Venta.respuesta[0].Serie.concat(" - ", Venta.respuesta[0].NumeroComprobante),
                            DireccionDeLlegada: Venta.respuesta[0].DireccionCliente,
                        })
                        // console.log(Venta)
                        await this.getUbigeoByCodigoUbigeo(Venta.respuesta[0].Ubigeo)
                    }
                )
                .catch(error => this.setState({error, isLoading: false}));
        }
    }

    getUbigeoByCodigoUbigeo = async (Ubigeo) => {
        const UbigeoLlegada = this.state.dataUbigeos.results.filter((item) => {
            return item.Ubigeo === Ubigeo
        });
        // console.log(UbigeoLlegada[0].IdUbigeo)
        // console.log(UbigeoLlegada)
        await Promise.all([ //Cargar provincias y distritos
            await this.CargarProvinciasLlegada(UbigeoLlegada[0].CodRegion),
            await this.CargarDistritosLlegada(UbigeoLlegada[0].CodProvincia)
        ]).then(() => {
            this.setState({
                IdUbiLlegada: UbigeoLlegada[0].IdUbigeo,
                RegionLlegada: UbigeoLlegada[0].CodRegion,
                ProvinciaLlegada: UbigeoLlegada[0].CodProvincia,
                DistritoLlegada: UbigeoLlegada[0].IdUbigeo,
            })

        })
    }

    CargarProvinciasLlegada = async (codreg) => {
        // console.log("codreg",codreg)
        const dataP = await this.state.dataUbigeos.results.filter((item) => {
            return item.CodRegion === codreg && item.CodDistrito === "00" && item.CodProvincia !== "00"
        });
        // console.log(dataP);
        this.setState({
            dataprovLlegada: dataP,
            RegionLlegada: codreg,
            ProvinciaLlegada: null,
            datadistLlegada: [],
        });
        //console.log(this.state);
    };

    CargarDistritosLlegada = async (codprov) => {
        // console.log("codprov",codprov)
        const dataD = await this.state.dataUbigeos.results.filter((item) => {
            return item.CodRegion === this.state.RegionLlegada && item.CodProvincia === codprov && item.CodDistrito !== "00"
        });

        // console.log(this.state.DistritoLlegada)
        this.setState({
            datadistLlegada: dataD,
            ProvinciaLlegada: codprov,
            DistritoLlegada: dataD.CodDistrito,
            IdUbigeoLlegada: this.state.IdUbigeoLlegada

        });
    };

    ObtenerDetalleVenta = async (IdRegistroVentas, data) => {

        if (String(this.state.IdTipoDocumentoSunat) === String(NOTA_EGRESO)) {
            const res = await fetch(`/api/movimientosAlmacen/detallemovimiento/list/${IdRegistroVentas}`)
            const resData = await res.json();
            const movs = resData[1];

            await this.cargarUbigeoAlmacen(data.IdAlmacen)

            this.setState({
                DetallesVentaNuevo: movs.map(m => {
                    return {...m, Peso: 0, Observacion: ''}
                }),
                IdAlmacen: data.IdAlmacen
            })

        } else if (String(this.state.IdTipoDocumentoSunat) === String(BOLETA) || String(this.state.IdTipoDocumentoSunat) === String(FACTURA)) {
            fetch(`/api/ventas/detalles/${IdRegistroVentas}`)
                .then(response => response.json())
                .then(async DetallesVenta => {

                        await this.ObtenerIdAlmacenPorIdStock(DetallesVenta.respuesta[0].IdStock)

                        this.setState({
                            DetallesVentaNuevo: [],
                            DetallesVenta: DetallesVenta.respuesta,
                            isLoading: false
                        })

                        let detReg = DetallesVenta.respuesta.map(dp => ({
                            ...dp,
                            Peso: 0,
                            Observacion: ''
                        }))

                        this.setState({
                            DetallesVentaNuevo: detReg
                        })
                    }
                )
                .catch(error => this.setState({error, isLoading: false}));
        }


    }

    ObtenerIdAlmacenPorIdStock = async (IdStock) => {
        try {
            const response = await fetch(`/api/guiaRemision/AlmacenPorStock/${IdStock}`);
            const data = await response.json();

            this.setState({
                // loading: false,
                IdAlmacen: data.Almacen[0].IdAlmacen
            })
            await this.cargarUbigeoAlmacen(data.Almacen[0].IdAlmacen)
            // console.log(data)
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }

    handleMonthPickerChange = async (maskedValue, selectedYear, selectedMonth) => {
        this.setState({
            Year: selectedYear,
            Mes: selectedMonth
        });
        if (String(this.state.IdTipoDocumentoSunat) === String(FACTURA) ||
            String(this.state.IdTipoDocumentoSunat) === String(BOLETA)) {
            await this.gerRegistrosVentaPorTurnos(selectedMonth, selectedYear);
        } else if (String(this.state.IdTipoDocumentoSunat) === String(NOTA_EGRESO)) {
            await this.getNotasEgreso(selectedMonth, selectedYear, NOTA_EGRESO);
        } else if (String(this.state.IdTipoDocumentoSunat) === String(VALE)) {
            await this.gerValesPorTurnos(selectedMonth, selectedYear);
        }
    }

    gerValesPorTurnos = async (Mes, Year) => {
        await fetch(`/api/guiaRemision/Vlista/${Mes + 1}/${Year}`)
            .then(response => response.json())
            .then(ventas => {
                    this.setState({
                        ventas: ventas.respuesta
                    })
                    // console.log(ventas)
                }
            )
            .catch(error => this.setState({error, loading: false}));
    }

    gerRegistrosVentaPorTurnos = async (Mes, Year) => {

        await fetch(`/api/guiaRemision/RVlista/${Mes + 1}/${Year}?idTipoDoc=${this.state.IdTipoDocumentoSunat}`)
            .then(response => response.json())
            .then(ventas => {
                    let ventasRF = []
                    //console.log({ventas})
                    if (this.state.IdTipoDocumentoSunat === 4) {
                        ventasRF = ventas.respuesta.filter((item) => {
                            return item.TipoComprobante.toUpperCase() === "Boleta de venta Electronica".toUpperCase()
                        })
                    } else {
                        ventasRF = ventas.respuesta.filter((item) => {
                            return item.TipoComprobante === "Factura Electronica"
                        })
                    }
                    this.setState({
                        ventas: ventasRF
                    })
                }
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    gerOrdenesCompra = async () => {
        await fetch(`/api/ordenescompra/list/suc`)
            .then(response => response.json())
            .then(response => {
                const { ordenesCompra: [, ordenes] } = response;
                this.setState({
                    ventas: ordenes
                })
            })
    }

    getCorrelativo = async () => {
        // this.setState({loading:true, error:null})
        try {
            const response = await fetch('/api/guiaRemision/Correlativo');
            const data = await response.json();
            // console.log(data)
            this.setState({
                Serie: data.Correlativo[0].Serie,
                NumeroComprobante: data.Correlativo[0]._NumeroComprobante,
                loading: true,
            })
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }

    getEmpresasTransportistas = async () => {
        try {
            const response = await fetch('/api/guiaRemision/empresas-transportistas');
            const data = await response.json();

            this.setState({
                dataEmpresasTransportistas: data
            })
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }

    getVehiculos = async () => {
        try {
            const response = await fetch('/api/vehiculos/');
            const data = await response.json();
            this.setState({
                dataVehiculos: data
            })
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }

    getTransportistas = async () => {
        // this.setState({loading:true, error:null})
        try {
            const response = await fetch('/api/transportistas/');
            const data = await response.json();
            this.setState({
                // loading: false,
                dataTransportistas: data
            })
            // console.log(data)
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }

    getMotivosDeTraslado = async () => {
        // this.setState({ loading: true, error: null });
        try {
            const response = await fetch(`/api/guiaRemision/MotivosDeTraslado`);
            const data = await response.json();
            // console.log(data)
            this.setState({
                dataMotivos: data,
            });
            // console.log(data)
        } catch (error) {
            this.setState({
                error: error,
            });
        }
    }

    getInfoSucursal = async () => {
        // this.setState({ loading: true, error: null });
        try {
            const response = await fetch(`/api/guiaRemision/InfoEmpresa`);
            const data = await response.json();
            if (data[0].IdAlmacen != null) {
                this.setState({
                    // loading: true,
                    IdSucursal: data[0].IdSucursal,
                    dataSucursal: data,
                    Ruc: data[0].Ruc,
                    RazonSocial: data[0].RazonSocial,
                    Sucursal: data[0].Nombre,
                    Direccion: data[0].Direccion,
                    IdEmpresa: data[0].IdEmpresa,
                    // IdAlmacen:data[0].IdAlmacen
                });
            } else {
                this.setState({
                    // loading: true,
                    IdSucursal: data[0].IdSucursal,
                    dataSucursal: data,
                    Ruc: data[0].Ruc,
                    RazonSocial: data[0].RazonSocial,
                    Sucursal: data[0].Nombre,
                    Direccion: data[0].Direccion,
                    IdEmpresa: data[0].IdEmpresa,
                    // IdAlmacen:0
                });
            }

            this.getCorrelativo();
            // console.log(this.state.dataSucursal[0])
            // this.obtenerAlmacenes(data[0].IdSucursal);
        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }

    }

    listaUbigeos = async () => {
        // this.setState({ loading: true, error: null });
        try {
            const response = await fetch(`/api/clientes/ubigeos/`);
            const data = await response.json();
            // console.log(data)
            this.setState({
                // loading: false,
                dataUbigeos: data,
            });
            const dataR = await this.state.dataUbigeos.results.filter((item) => {
                return item.CodProvincia === "00"
            });
            this.setState({
                dataregSalida: dataR,
                dataregLlegada: dataR
            });

        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    };

    handleChangeRegionSalida = (e) => {
        const dataP = this.state.dataUbigeos.results.filter((item) => {
            return item.CodRegion === e.target.value && item.CodDistrito === "00" && item.CodProvincia !== "00"
        });
        // console.log(dataP);
        this.setState({
            dataprovSalida: dataP,
            IdUbigeoSalida: e.target.value,
            RegionSalida: e.target.value,
            ProvinciaSalida: null,
            datadistSalida: [],
        });
        //console.log(this.state);
    };

    handleChangeRegionLlegada = (e) => {
        const dataP = this.state.dataUbigeos.results.filter((item) => {
            return item.CodRegion === e.target.value && item.CodDistrito === "00" && item.CodProvincia !== "00"
        });
        // console.log(dataP);
        this.setState({
            dataprovLlegada: dataP,
            IdUbigeoLlegada: e.target.value,
            RegionLlegada: e.target.value,
            ProvinciaLlegada: null,
            datadistLlegada: [],
        });
        //console.log(this.state);
    };

    handleChangeProvinciaLlegada = (e) => {
        const dataD = this.state.dataUbigeos.results.filter((item) => {
            return item.CodRegion === this.state.RegionLlegada && item.CodProvincia === e.target.value && item.CodDistrito !== "00"
        });
        this.setState({
            datadistLlegada: dataD,
            IdUbigeoLlegada: `${this.state.RegionLlegada}${e.target.value}`,
            ProvinciaLlegada: e.target.value,
            DistritoLlegada: null,
        });
    };

    handleChangeDistritoLlegada = (e) => {
        // console.log(e.target.value)
        this.setState({
            [e.target.name]: e.target.value,
            IdUbigeoLlegada: `${this.state.RegionLlegada}${this.state.ProvinciaLlegada}${e.target.value}`,
            IdUbiLlegada: e.target.value
        });
        // console.log(this.state.IdUbigeoLlegada)
    };

    handleChangeProvinciaSalida = (e) => {
        const dataD = this.state.dataUbigeos.results.filter((item) => {
            return item.CodRegion === this.state.RegionSalida && item.CodProvincia === e.target.value && item.CodDistrito !== "00"
        });
        this.setState({
            datadistSalida: dataD,
            IdUbigeoSalida: e.target.value,
            ProvinciaSalida: e.target.value,
            DistritoSalida: null,
        });
    };

    handleChangeDistritoSalida = (e) => {
        const ubigeoCode = `${this.state.RegionSalida}${this.state.ProvinciaSalida}${e.target.value}`;
        const ubigeo = this.state.dataUbigeos.results.find((item) => {
            return item.Ubigeo === ubigeoCode
        });

        this.setState({
            [e.target.name]: e.target.value,
            IdUbigeoSalida: ubigeo.IdUbigeo
        });
    };

    handleChangeUnicos = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    handleVehiculoChange(e) {
        this.setState({IdVehiculo: e.value});
    }

    ListaVehiculos = (Vehiculos) => {
        return Vehiculos.map(Vehiculo => {
            //console.log(producto)
            return {name: Vehiculo.Modelo.concat(', ', Vehiculo.Placa), value: Vehiculo.IdVehiculo};
        });
    }

    handleEmpresaTransportistaChange(e) {
        this.setState({IdEmpresaTransportista: e.value});
    }

    ListaEmpresaTransportista = (EmpresasTransportistas) => {
        return EmpresasTransportistas.map(Empresa => {
            //console.log(producto)
            return {name: Empresa.NroDocumento.concat(' - ', Empresa.RazonSocial), value: Empresa.IdEmpresaTransportista};
        });
    }

    handleTransportistaChange(e) {
        this.setState({
            IdTransportista: e.value,
            HabilitarCoPiloto: false
        });
    }

    handleCoTransportistaChange(e) {
        this.setState({IdCoTransportista: e.value});
        // this.state.IdCoTransportista = e.value
    }

    ListaTransportistas = (Transportistas) => {
        return Transportistas.map(Transportista => {
            //console.log(producto)
            return {name: Transportista.Empleado, value: Transportista.IdTransportista};
        });
    }

    handleAlmacenChange = async (e) => {
        this.setState({IdAlmacen: e.value});
        await this.cargarUbigeoAlmacen(e.value);
    }

    handleAlmacenLlegadaChange = async (e) => {
        this.setState({IdAlmacenLlegada: e.value});

        const almacen = this.state.dataAlmacenes.Almacenes.find(x => x.IdAlmacen == e.value);

        if (!almacen) {
            return;
        }

        const ubigeoLlegada = this.state.dataUbigeos.results.filter(obj => {
            return obj.IdUbigeo === almacen.IdUbigeo
        })

        this.getUbigeoByCodigoUbigeo(ubigeoLlegada[0].Ubigeo)
        
        this.setState({
            NumeroDocDestinatario: this.state.Ruc, 
            RazonSocialDestinatario: this.state.RazonSocial,
            DireccionDeLlegada: almacen.Direccion
        })
    }


    cargarUbigeoAlmacen = async (IdAlmacen) => {
        const Almacen = this.state.dataAlmacenes.Almacenes.filter(obj => {
            return obj.IdAlmacen === IdAlmacen //&& obj.IdSucursal == this.state.IdSucursal no recuerdo para que fue esta condicional
        })

        const UbigeoSalida = this.state.dataUbigeos.results.filter(obj => {
            return obj.IdUbigeo === Almacen[0].IdUbigeo
        })

        await this.CargarProvincias(UbigeoSalida[0].CodRegion);
        await this.CargarDistritos(UbigeoSalida[0].CodProvincia);

        this.setState({
            IdUbigeoSalida: Almacen[0].IdUbigeo,
            RegionSalida: UbigeoSalida[0].CodRegion,
            ProvinciaSalida: UbigeoSalida[0].CodProvincia,
            DistritoSalida: UbigeoSalida[0].CodDistrito,
            DireccionDeSalida: Almacen[0].Direccion
        })
    }

    CargarProvincias = async (codreg) => {
        // console.log("codreg",codreg)
        const dataP = await this.state.dataUbigeos.results.filter((item) => {
            return item.CodRegion === codreg && item.CodDistrito === "00" && item.CodProvincia !== "00"
        });
        // console.log(dataP);
        this.setState({
            dataprovSalida: dataP,
            RegionSalida: codreg,
            ProvinciaSalida: null,
            datadistSalida: [],
        });
        //console.log(this.state);
    };

    CargarDistritos = async (codprov) => {
        // console.log("codprov",codprov)
        const dataD = await this.state.dataUbigeos.results.filter((item) => {
            return item.CodRegion === this.state.RegionSalida && item.CodProvincia === codprov && item.CodDistrito !== "00"
        });
        this.setState({
            datadistSalida: dataD,
            ProvinciaSalida: codprov,
            DistritoSalida: null

        });
    };

    ListaAlmacenes = (Almacenes, excludeSalida = false) => {
        const almacenes =  Almacenes.map(Almacen => {
            //console.log(producto)
            return {name: Almacen.Nombre, value: Almacen.IdAlmacen};
        });

        if (!excludeSalida) {
            return almacenes
        }

        return almacenes.filter(a => a.value != this.state.IdAlmacen)
    }

    handleChangeCheckBox = (e) => {
        const coTransportistas = this.state.dataTransportistas.Transportistas.filter(coTransportista => {
            return coTransportista.IdTransportista !== this.state.IdTransportista
        })

        if (this.state.HabilitarCoPiloto === false) {
            // Aqui debe ir la llamada a crear la nueva lista sin el id del transportista seleccionado
            if (this.state.IdTransportista != null) {
                if (coTransportistas.length > 0) {
                    this.setState({
                        HabilitarCoPiloto: true,
                        dataCoTransportistas: coTransportistas
                    })
                } else {
                    return Notify("Registre otro Conductor", "warning", 1500);
                }
            } else {
                return Notify("Primero seleccione un Conductor", "warning", 1500);
            }
            // console.log(coTransportistas)
        } else {
            this.setState({
                HabilitarCoPiloto: false,
                dataCoTransportistas: [],
                IdCoTransportista: null
            })
            // Aqui debemos poner la lista a cero. incluyendo el IdCopiloto
        }
    }

    async handleOpenModal() {
        if (this.state.IdTipoDocumentoSunat === null || this.state.IdTipoDocumentoSunat === '') {
            return Notify("Debes seleccionar primero un tipo de documento de origen", "warning", 2000)
        } else {

            this.setState({
                statusModal: true
            });
        }

    }

    handleOnCloseModal() {
        this.setState({
            statusModal: false
        });
    }

    // handleChangePeso = (e, item) => {
    //     if (this.state.IdTipoDocumentoSunat === 2 || this.state.IdTipoDocumentoSunat === 4) {
    //         this.state.DetallesVentaNuevo.map(obj => {
    //             if (obj.IdDetalleVenta == item.IdDetalleVenta) {
    //                 obj.Peso = e.target.value
    //             }
    //         })
    //     } else {
    //         this.state.DetallesVentaNuevo.map(obj => {
    //             if (obj.IdDetalleVale == item.IdDetalleVale) {
    //                 obj.Peso = e.target.value
    //             }
    //         })
    //     }

    //     this.calcularPesoTotal()
    //     this.setState(this.state.DetallesVentaNuevo)
    // }
    handleChangePeso = (e, item) => {
        const updatedDetallesVentaNuevo = this.state.DetallesVentaNuevo.map(obj => {
            if (this.state.IdTipoDocumentoSunat === 2 || this.state.IdTipoDocumentoSunat === 4) {
                if (obj.IdDetalleVenta === item.IdDetalleVenta) {
                    return {...obj, Peso: e.target.value};
                }
            } else {
                if ('IdDetalleVale' in item && (obj.IdDetalleVale === item.IdDetalleVale)) {
                    return {...obj, Peso: e.target.value};
                }

                if ('IdDetalleOC' in item && (obj.IdDetalleOC == item.IdDetalleOC)) {
                    return {...obj, Peso: e.target.value};
                }
                
                if ('IdDetalleMovimientosAlmacen' in item && (obj.IdDetalleMovimientosAlmacen == item.IdDetalleMovimientosAlmacen)) {
                    return {...obj, Peso: e.target.value};
                }
            }
            return obj;
        });

        this.setState({DetallesVentaNuevo: updatedDetallesVentaNuevo}, () => {
            this.calcularPesoTotal();
        });
    };


    // obBlurInputNumerIsEmpty = (e, item) => {

    //     this.state.DetallesVentaNuevo.map(obj => {
    //         if (obj.IdDetalleVenta == item.IdDetalleVenta) {
    //             if (e.target.value == '') {
    //                 obj.Peso = 0
    //             }
    //         }
    //     })
    //     this.setState(this.state.DetallesVentaNuevo)
    // }
    obBlurInputNumerIsEmpty = (e, item) => {
        const updatedDetallesVentaNuevo = this.state.DetallesVentaNuevo.map(obj => {
            if (obj.IdDetalleVenta === item.IdDetalleVenta) {
                if (e.target.value === '') {
                    return {...obj, Peso: 0};
                }
            }
            return obj;
        });

        this.setState({DetallesVentaNuevo: updatedDetallesVentaNuevo});
    };

    // calcularPesoTotal = () => {
    //     let total = 0
    //     this.state.DetallesVentaNuevo.map(obj => {
    //         if (obj.Peso == '') {
    //             total = total + 0
    //         } else {
    //             total = total + parseFloat(obj.Peso)
    //         }

    //     })
    //     this.setState({
    //         Peso: total
    //     })
    // }
    calcularPesoTotal = () => {
        let total = 0
        this.state.DetallesVentaNuevo.forEach(obj => {
            if (obj.Peso === '') {
                total = total + 0
            } else {
                total = total + parseFloat(obj.Peso)
            }
        })

        this.setState({
            Peso: total
        })
    }


    // handleChangeObservacion = (e, item) => {

    //     this.state.DetallesVentaNuevo.map(obj => {
    //         if (obj.IdDetalleVenta == item.IdDetalleVenta) {
    //             obj.Observacion = e.target.value
    //         }
    //     })
    //     this.setState(this.state.DetallesVentaNuevo)
    // }
    handleChangeObservacion = (e, item) => {
        const {DetallesVentaNuevo} = this.state;

        const updatedDetalles = DetallesVentaNuevo.map(obj => {
            if (obj.IdDetalleVenta === item.IdDetalleVenta) {
                return {
                    ...obj,
                    Observacion: e.target.value
                };
            }

            return obj;
        });

        this.setState({
            DetallesVentaNuevo: updatedDetalles
        });
    }


    newGuiaRemision = async (e) => {
        e.preventDefault();

        const res = await fetch("/api/guiaRemision/add", {
            method: "POST",
            body: JSON.stringify({
                IdTipoDocumentoSunat: this.state.IdTipoDocumentoSunat,
                IdSucursal: this.state.IdSucursal,
                FechaEmision: this.state.FechaDeEmision,
                FechaInicioTraslado: this.state.FechaDeInicioDeTraslado,
                IdRegistroVenta: this.state.isVale ? 0 : this.state.IdRegistroVentas,
                IdVale: this.state.isVale ? this.state.IdRegistroVentas : 0,
                IdMotivoDeTraslado: this.state.IdMotivoDeTraslado,
                DescripcionMotivoTraslado: this.state.DescripcionMotivoTraslado,
                IdUbigeoSalida: this.state.IdUbigeoSalida,
                Serie: this.state.Serie,
                NumeroComprobante: this.state.NumeroComprobante,
                DireccionSalida: this.state.DireccionDeSalida,
                IdUbigeoLlegada: this.state.IdUbiLlegada,
                DireccionLLegada: this.state.DireccionDeLlegada,
                IdVehiculo: this.state.IdVehiculo,
                IdTransportista: this.state.IdTransportista,
                IdCoTransportista: this.state.IdCoTransportista,
                Peso: this.state.Peso,
                ComentarioAdicional: this.state.ComentarioAdicional,
                numYCorrelativo: this.state.numYCorrelativoRV,
                IdAlmacenSalida: this.state.IdAlmacen,
                numContenedor: this.state.numContenedor || "",
                numBultos: this.state.numBultos,
                referenceDoc: this.getDocReference(this.state.IdTipoDocumentoSunat),
                TipoDocDestinatario: this.state.TipoDocDestinatario && this.state.NumeroDocDestinatario.length === 11 ? RUC : DNI,
                NumeroDocDestinatario: this.state.NumeroDocDestinatario,
                RazonSocialDestinatario: this.state.RazonSocialDestinatario,
                IdEmpresaTransportista: this.state.IdEmpresaTransportista,
                ordenCompraDoc: {
                    serie: this.state.OrdenCompra.Serie,
                    correlativo: this.state.OrdenCompra.Correlativo,
                    tipoDoc: (() => {
                        if (this.state.OrdenCompra.IdTipoDocumentoSunat == "") {
                            return ""
                        }

                        const tipos = this.state.datatipoDocumento.tipoDocumento;
                        const tipoDoc = tipos.find(x => x.IdTipoDocumentoSunat == this.state.OrdenCompra.IdTipoDocumentoSunat)

                        if (!tipoDoc) {
                            return ""
                        }
                        
                        return tipoDoc.CodigoDoc
                    })(),
                }
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })

        const data = await res.json();

        if (res.ok)
            await this.NewDetalleGuiaRemision(data.IdGuiaRemision);
        else
            notificarError(data.msg)

    }

    NewDetalleGuiaRemision = async (IdGuiaRemision) => {
        this.setState({loading: true, error: null});
        try {
            this.state.DetallesVentaNuevo.map(async (item) => {
                // console.log(item)
                await fetch("/api/guiaRemision/addDetalle", {
                    method: "POST",
                    body: JSON.stringify({
                        IdGuiaRemision: IdGuiaRemision,
                        Cantidad: item.Cantidad,
                        NombreProducto: item.NombreProducto,
                        IdStock: item.IdStock,
                        IdTipoStock: item.IdTipoStock,
                        IdAfectacionIGV: item.IdAfectacionIgv,
                        PrecioVenta: item.PrecioVenta,
                        Importe: item.Importe,
                        Codigo: item.Codigo,
                        Indice: item.Indice,
                        Unidad: item.Unidad,
                        Descuento: item.Descuento,
                        Peso: item.Peso,
                        Observacion: item.Observacion,
                    }),
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                }).then(res => res.json())
            })
            Notify("Guía de Remisión registrada.", "success", 1500)
            this.props.history.push('/GuiaRemision/List')

        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
    }

    getDocReference(tipoDoc) {
        if (this.isVenta(tipoDoc))
            return {
                table: "RegistroVentas",
                pk: "IdRegistroVenta",
                value: this.state.IdRegistroVentas,
                tipoDocAttr: "IdTipoComprobante"
            }
        if (String(tipoDoc) === String(NOTA_EGRESO))
            return {
                table: "MovimientosAlmacen",
                pk: "IdMovimientoAlmacen",
                value: this.state.IdRegistroVentas,
                tipoDocAttr: "IdTipoDocumentoSunat"
            }
        if (String(tipoDoc) === String(VALE))
            return {
                table: "Vales",
                pk: "IdVale",
                value: this.state.IdRegistroVentas,
                tipoDocAttr: "IdTipoComprobante"
            }
        if (String(tipoDoc) === String(ORDEN_COMPRA))
            return {
                table: "OrdenesCompra",
                pk: "IdOrdenCompra",
                value: this.state.IdRegistroVentas,
                tipoDocAttr: "IdTipoComprobante"
            }
        return {};
    }

    isVenta(tipoDoc) {
        return String(tipoDoc) === String(BOLETA) || String(tipoDoc) === String(FACTURA);
    }

    async onSaveVehiculo(data) {
        await this.getVehiculos()
        this.setState({IdVehiculo: data.data.IdVehiculo, isOpenModalVehiculo: false})
    }

    async onSaveTransportista(data) {
        await this.getTransportistas();
        this.setState({IdTransportista: data.IdTransportista, isOpenModalTrans: false})
    }

    async onSaveEmpresaTransportista(data) {
        await this.getEmpresasTransportistas();
        this.setState({IdEmpresaTransportista: data.IdEmpresaTransportista, isOpenModalEmpresaTransportista: false})
    }

    render() {
        if (this.state.loading === false && this.state.Serie === '') {
            return <PageLoading/>;
        }
        return (
            <section className='pt-3'>
                <div className='container'>
                    <div className="  mt-4">
                        <BotonCancelar to="/GuiaRemision/List"/>
                    </div>
                    <form onSubmit={this.newGuiaRemision}
                        // className="d-flex justify-content-center"
                    >
                        <div
                            className=""
                        >
                            <div className="row AlmacenBoton pb-3 d-flex justify-content-end">
                                <div className='add-pad pt-3'>Guía de Remisión -
                                    Remitente {this.state.Serie} - {this.state.NumeroComprobante}</div>
                            </div>
                            <div className="caja-fondo-blanco-m-2">
                                <b className=" ">Ruc</b>
                                <div className="input__Almacen">
                                    <input
                                        name="Ruc"
                                        className="input__linea"
                                        disabled
                                        type="text"
                                        value={this.state.Ruc}
                                    />
                                </div>
                            </div>
                            <div className=" caja-fondo-blanco-m-2">
                                <b className=" ">Razón Social</b>
                                <div className="input__Almacen">
                                    <input
                                        name="RazonSocial"
                                        className="input__linea "
                                        disabled
                                        type="text"
                                        value={this.state.RazonSocial}
                                    />
                                </div>
                            </div>

                            <div className=" caja-fondo-blanco-m-2">
                                <b className=" ">Sucursal</b>
                                <div className="input__Almacen">
                                    <input
                                        name="Sucursal"
                                        className="input__linea"
                                        disabled
                                        type="text"
                                        value={this.state.Sucursal}
                                    />
                                </div>
                            </div>
                            <div className=" caja-fondo-blanco-m-2">
                                <b className=" ">Dirección</b>
                                <div className="input__Almacen">
                                    <input
                                        name="Direccion"
                                        className="input__linea"
                                        disabled
                                        type="text"
                                        value={this.state.Direccion}
                                    />
                                </div>
                            </div>

                            <div className=" caja-fondo-blanco-m-2">
                                <b className=" ">Fecha de Emisión </b>
                                <div className="input__Almacen">
                                    <input

                                        disabled
                                        name="FechaDeEmision"
                                        onChange={this.handleChangeUnicos}
                                        value={this.state.FechaDeEmision}
                                        className="input__linea"
                                        type="date"
                                    />
                                </div>
                            </div>
                            <div className=" caja-fondo-blanco-m-2">
                                <b className=" ">*Fecha de Inicio de Traslado</b>
                                <div className="input__Almacen">
                                    <input

                                        name="FechaDeInicioDeTraslado"
                                        onChange={this.handleChangeUnicos}
                                        className="input__linea"
                                        defaultValue={this.state.FechaDeInicioDeTraslado}
                                        min={this.state.Hoy}
                                        type="date"/>
                                </div>
                            </div>

                            <div className=" caja-fondo-blanco-m-2">
                                <b className=" ">Tipo de Documento de Origen</b>
                                <div className="input__Almacen col-12 no-pad pt-3">
                                    <SelectSearch
                                        name="IdTipoDocumentoSunat"
                                        value={this.state.IdTipoDocumentoSunat}
                                        onChange={(e) => this.handleTipoDocSunatChange(e)}
                                        options={this.ListaTipoDocSunat(this.state.datatipoDocumento.tipoDocumento)}
                                        className="select-search-box"
                                        placeholder="Seleccionar Tipo de Documento"
                                    />
                                </div>
                            </div>
                            <div className=" caja-fondo-blanco-m-2">
                                <div className="input__Almacen">
                                    <div className='row'>
                                        <div className='col-12 col-sm-6 mb-3'><b className="ml-1">Seleccionar Documento
                                            de Origen</b></div>

                                        <div className='col-12 col-sm-6 d-flex justify-content-end'>
                                            <BotonAddDoc
                                                className=""
                                                name="docOrigen"
                                                type="button"
                                                onClick={this.handleOpenModal}
                                            />
                                        </div>
                                    </div>

                                    <input type="text"
                                           className="input__linea"
                                           disabled
                                           value={this.state.numYCorrelativoRV}/>
                                </div>
                            </div>
                            <div className=" caja-fondo-blanco-m-2">
                                <b className=" ">*Motivo de Traslado</b>
                                <div className="input__Almacen">
                                    <select
                                        name="IdMotivoDeTraslado"
                                        className="input__linea"
                                        value={this.state.IdMotivoDeTraslado}
                                        onChange={this.handleChangeUnicos}>{
                                        this.state.dataMotivos.Motivos.map((obj) => {
                                            return <option key={obj.IdMotivoDeTraslado}
                                                           value={obj.IdMotivoDeTraslado}>{obj.Nombre}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className=" caja-fondo-blanco-m-2">
                                <b className=" ">* Descipción del Motivo de Traslado</b>
                                <div className="input__Almacen">
                                    <input
                                        name="DescripcionMotivoTraslado"
                                        className="input__linea"
                                        type="text"
                                        value={this.state.DescripcionMotivoTraslado}
                                        onChange={this.handleChangeUnicos}
                                    />
                                </div>
                            </div>

                            {
                                this.state.IdTipoDocumentoSunat == ORDEN_COMPRA ? <>
                                    <div className=" caja-fondo-blanco-m-2">
                                        <b className=" ">Tipo de documento asociado</b>
                                        <div className="input__Almacen col-12 no-pad pt-3">
                                            <SelectSearch
                                                name="IdTipoDocumentoSunat"
                                                value={this.state.OrdenCompra.IdTipoDocumentoSunat}
                                                onChange={(e) => this.handleOrdenCompraTipoDocChange(e)}
                                                options={this.ListaTipoDocSunat(this.state.datatipoDocumento.tipoDocumento, [FACTURA, BOLETA])}
                                                className="select-search-box"
                                                placeholder="Seleccionar Tipo de Documento"
                                            />
                                        </div>
                                    </div>

                                    <div className=" caja-fondo-blanco-m-2">
                                        <b className=" ">*Serie</b>
                                        <div className="input__Almacen">
                                            <input
                                                name="Serie"
                                                className="input__linea"
                                                type="text"
                                                value={this.state.OrdenCompra.Serie}
                                                onChange={this.handleChangeDocOrdenCompra}
                                            />
                                        </div>
                                    </div>

                                    <div className=" caja-fondo-blanco-m-2">
                                        <b className=" ">*Correlativo</b>
                                        <div className="input__Almacen">
                                            <input
                                                name="Correlativo"
                                                className="input__linea"
                                                type="text"
                                                value={this.state.OrdenCompra.Correlativo}
                                                onChange={this.handleChangeDocOrdenCompra}
                                            />
                                        </div>
                                    </div>
                                </> : null
                            }

                            {
                                this.state.IdTipoDocumentoSunat == ORDEN_COMPRA ? <>
                                    <div className="row form-group col-12">
                                        <b className=" ">*INFORMACIÓN DE SALIDA</b>
                                    </div>
                                    <div className=" caja-fondo-blanco-m-2">
                                        <b className=" ">*Número de Documento</b>
                                        <div className="input__Almacen">

                                            <input
                                                disabled={this.state.destinatarioDisabled}
                                                name="NumeroDocDestinatario"
                                                className="input__linea"
                                                type="text"
                                                value={this.state.NumeroDocDestinatario}
                                                onChange={this.handleChangeUnicos}
                                            />
                                        </div>
                                    </div>
                                    <div className=" caja-fondo-blanco-m-2">
                                        <b className=" ">*Apellidos y nombres o razón social</b>
                                        <div className="input__Almacen">
                                            <input
                                                disabled={this.state.destinatarioDisabled}
                                                name="RazonSocialDestinatario"
                                                className="input__linea"
                                                type="text"
                                                value={this.state.RazonSocialDestinatario}
                                                onChange={this.handleChangeUnicos}
                                            />
                                        </div>
                                    </div>
                                    <div className=" caja-fondo-blanco-m-2">
                                        <b className=" ">*Ubigeo de Llegada</b>
                                        <div className="input__Almacen">
                                            <select onChange={this.handleChangeRegionLlegada}
                                                    className="input__linea col-4" name="RegionLlegada"
                                                    value={this.state.RegionLlegada}>
                                                <option value={null}>Selecccione región</option>
                                                {
                                                    this.state.dataregLlegada.map((item) => {
                                                        return <option key={item.IdUbigeo}
                                                                    value={item.CodRegion}> {item.NombreUbigeo} </option>
                                                    })
                                                }
                                            </select>
                                            <select onChange={this.handleChangeProvinciaLlegada}
                                                    className="input__linea col-4" name="ProvinciaLlegada"
                                                    value={this.state.ProvinciaLlegada || ""}>
                                                <option value={""}>Selecccione provincia</option>
                                                {
                                                    this.state.dataprovLlegada.map((item) => {
                                                        return <option key={item.IdUbigeo}
                                                                    value={item.CodProvincia}> {item.NombreUbigeo} </option>
                                                    })
                                                }
                                            </select>
                                            <select onChange={this.handleChangeDistritoLlegada}
                                                    className="input__linea col-4" name="DistritoLlegada"
                                                    value={this.state.DistritoLlegada}>
                                                <option value={null}>Selecccione distrito</option>
                                                {
                                                    this.state.datadistLlegada.map((item) => {
                                                        return <option key={item.IdUbigeo} id={item.IdUbigeo}
                                                                    value={item.IdUbigeo}> {item.NombreUbigeo} </option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className=" caja-fondo-blanco-m-2">
                                        <b className=" ">*Dirección de Llegada</b>
                                        <div className="input__Almacen">
                                            <input
                                                name="DireccionDeLlegada"
                                                className="input__linea"
                                                type="text"
                                                value={this.state.DireccionDeLlegada}
                                                onChange={this.handleChangeUnicos}
                                            />
                                        </div>
                                    </div>
                                </> : <>
                                <div className="row form-group col-12">
                                    <b className=" ">*INFORMACIÓN DE SALIDA</b>
                                </div>
                                <div className=" caja-fondo-blanco-m-2">
                                    <b className=" ">*Punto de Partida</b>
                                    <div className="input__Almacen pt-3">
                                        <SelectSearch
                                            name="IdAlmacen"
                                            value={this.state.IdAlmacen}
                                            onChange={(e) => this.handleAlmacenChange(e)}
                                            options={this.ListaAlmacenes(this.state.dataAlmacenes.Almacenes)}
                                            className="select-search-box"
                                            placeholder="Seleccionar Almacen"
                                        />
                                    </div>
                                </div>
                                <div className=" caja-fondo-blanco-m-2">
                                    <b className=" ">Ubigeo de Salida</b>
                                    <div className="input__Almacen">
                                        <select

                                            disabled
                                            className="input__linea col-4"
                                            name="RegionSalida"
                                            value={this.state.RegionSalida || ""}>
                                            <option value={""}>Selecccione región</option>
                                            {
                                                this.state.dataregSalida.map((item) => {
                                                    return <option key={item.IdUbigeo}
                                                                value={item.CodRegion}> {item.NombreUbigeo} </option>
                                                })
                                            }
                                        </select>
                                        <select

                                            disabled
                                            className="input__linea col-4"
                                            name="ProvinciaSalida"
                                            value={this.state.ProvinciaSalida || ""}>
                                            <option value={""}>Selecccione provincia</option>
                                            {
                                                this.state.dataprovSalida.map((item) => {
                                                    return <option key={item.IdUbigeo}
                                                                value={item.CodProvincia}> {item.NombreUbigeo} </option>
                                                })
                                            }
                                        </select>
                                        <select
                                            disabled
                                            className="input__linea col-4"
                                            name="DistritoSalida"
                                            value={this.state.DistritoSalida || ""}>
                                            <option value={""}>Selecccione distrito</option>
                                            {
                                                this.state.datadistSalida.map((item) => {
                                                    return <option key={item.IdUbigeo}
                                                                value={item.CodDistrito}> {item.NombreUbigeo} </option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className=" caja-fondo-blanco-m-2">
                                    <b className=" ">Dirección de Salida</b>
                                    <div className="input__Almacen">
                                        <input
                                            name="DireccionDeSalida"
                                            className="input__linea"
                                            type="text"
                                            value={this.state.DireccionDeSalida}
                                            onChange={this.handleChangeUnicos}
                                            disabled
                                        />
                                    </div>
                                </div>
                                </>
                            }

                            {
                                this.state.IdMotivoDeTraslado == MOTIVO_TRASLADO.ENTRE_ALMACENES ? <>
                                    <div className="row form-group col-12">
                                        <b className=" ">*INFORMACIÓN DE LLEGADA</b>
                                    </div>

                                    <div className=" caja-fondo-blanco-m-2">
                                        <b className=" ">*Punto de llegada</b>
                                        <div className="input__Almacen pt-3">
                                            <SelectSearch
                                                name="IdAlmacenLlegada"
                                                value={this.state.IdAlmacenLlegada}
                                                defaultValue
                                                onChange={(e) => this.handleAlmacenLlegadaChange(e)}
                                                options={this.ListaAlmacenes(this.state.dataAlmacenes.Almacenes, true)}
                                                className="select-search-box"
                                                placeholder="Seleccionar Almacen"
                                            />
                                        </div>
                                    </div>

                                    <div className=" caja-fondo-blanco-m-2">
                                    <b className=" ">*Ubigeo de Llegada</b>
                                    <div className="input__Almacen">
                                        <select className="input__linea col-4" name="RegionLlegada"
                                                value={this.state.RegionLlegada} disabled>
                                            <option value={null}>Selecccione región</option>
                                            {
                                                this.state.dataregLlegada.map((item) => {
                                                    return <option key={item.IdUbigeo}
                                                                value={item.CodRegion}> {item.NombreUbigeo} </option>
                                                })
                                            }
                                        </select>
                                        <select className="input__linea col-4" name="ProvinciaLlegada"
                                                value={this.state.ProvinciaLlegada || ""} disabled>
                                            <option value={""}>Selecccione provincia</option>
                                            {
                                                this.state.dataprovLlegada.map((item) => {
                                                    return <option key={item.IdUbigeo}
                                                                value={item.CodProvincia}> {item.NombreUbigeo} </option>
                                                })
                                            }
                                        </select>
                                        <select className="input__linea col-4" name="DistritoLlegada"
                                                value={this.state.DistritoLlegada} disabled>
                                            <option value={null}>Selecccione distrito</option>
                                            {
                                                this.state.datadistLlegada.map((item) => {
                                                    return <option key={item.IdUbigeo} id={item.IdUbigeo}
                                                                value={item.IdUbigeo}> {item.NombreUbigeo} </option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className=" caja-fondo-blanco-m-2">
                                    <b className=" ">*Dirección de Llegada</b>
                                    <div className="input__Almacen">
                                        <input
                                            name="DireccionDeLlegada"
                                            className="input__linea"
                                            type="text"
                                            value={this.state.DireccionDeLlegada}
                                            disabled
                                        />
                                    </div>
                                </div>
                                </> : null
                            }

                            {
                                this.state.IdTipoDocumentoSunat == ORDEN_COMPRA ? <>
                                <div className="row form-group col-12">
                                    <b className=" ">*INFORMACIÓN DE LLEGADA</b>
                                </div>
                                <div className=" caja-fondo-blanco-m-2">
                                    <b className=" ">*Punto de Partida</b>
                                    <div className="input__Almacen pt-3">
                                        <SelectSearch
                                            name="IdAlmacen"
                                            value={this.state.IdAlmacen}
                                            defaultValue
                                            onChange={(e) => this.handleAlmacenChange(e)}
                                            options={this.ListaAlmacenes(this.state.dataAlmacenes.Almacenes)}
                                            className="select-search-box"
                                            placeholder="Seleccionar Almacen"
                                        />
                                    </div>
                                </div>
                                <div className=" caja-fondo-blanco-m-2">
                                    <b className=" ">Ubigeo de Salida</b>
                                    <div className="input__Almacen">
                                        <select
                                            className="input__linea col-4"
                                            name="RegionSalida"
                                            onChange={this.handleChangeRegionSalida}
                                            value={this.state.RegionSalida || ""}>
                                            <option value={""}>Selecccione región</option>
                                            {
                                                this.state.dataregSalida.map((item) => {
                                                    return <option key={item.IdUbigeo}
                                                                value={item.CodRegion}> {item.NombreUbigeo} </option>
                                                })
                                            }
                                        </select>
                                        <select
                                            onChange={this.handleChangeProvinciaSalida}
                                            className="input__linea col-4"
                                            name="ProvinciaSalida"
                                            value={this.state.ProvinciaSalida || ""}>
                                            <option value={""}>Selecccione provincia</option>
                                            {
                                                this.state.dataprovSalida.map((item) => {
                                                    return <option key={item.IdUbigeo}
                                                                value={item.CodProvincia}> {item.NombreUbigeo} </option>
                                                })
                                            }
                                        </select>
                                        <select
                                            onChange={this.handleChangeDistritoSalida}
                                            className="input__linea col-4"
                                            name="DistritoSalida"
                                            value={this.state.DistritoSalida || ""}>
                                            <option value={""}>Selecccione distrito</option>
                                            {
                                                this.state.datadistSalida.map((item) => {
                                                    return <option key={item.IdUbigeo}
                                                                value={item.CodDistrito}> {item.NombreUbigeo} </option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className=" caja-fondo-blanco-m-2">
                                    <b className=" ">Dirección de Salida</b>
                                    <div className="input__Almacen">
                                        <input
                                            name="DireccionDeSalida"
                                            className="input__linea"
                                            type="text"
                                            value={this.state.DireccionDeSalida}
                                            onChange={this.handleChangeUnicos}
                                        />
                                    </div>
                                </div>
                                </> : null 
                            }
                            
                            { this.state.IdMotivoDeTraslado != MOTIVO_TRASLADO.ENTRE_ALMACENES && this.state.IdTipoDocumentoSunat != ORDEN_COMPRA ? <>
                                <div className="row form-group col-12">
                                    <b className=" ">*DESTINATARIO</b>
                                </div>
                                <div className=" caja-fondo-blanco-m-2">
                                    <b className=" ">*Número de Documento</b>
                                    <div className="input__Almacen">

                                        <input
                                            disabled={this.state.destinatarioDisabled}
                                            name="NumeroDocDestinatario"
                                            className="input__linea"
                                            type="text"
                                            value={this.state.NumeroDocDestinatario}
                                            onChange={this.handleChangeUnicos}
                                        />
                                    </div>
                                </div>
                                <div className=" caja-fondo-blanco-m-2">
                                    <b className=" ">*Apellidos y nombres o razón social</b>
                                    <div className="input__Almacen">
                                        <input
                                            disabled={this.state.destinatarioDisabled}
                                            name="RazonSocialDestinatario"
                                            className="input__linea"
                                            type="text"
                                            value={this.state.RazonSocialDestinatario}
                                            onChange={this.handleChangeUnicos}
                                        />
                                    </div>
                                </div>
                                <div className=" caja-fondo-blanco-m-2">
                                    <b className=" ">*Ubigeo de Llegada</b>
                                    <div className="input__Almacen">
                                        <select onChange={this.handleChangeRegionLlegada}
                                                className="input__linea col-4" name="RegionLlegada"
                                                value={this.state.RegionLlegada}>
                                            <option value={null}>Selecccione región</option>
                                            {
                                                this.state.dataregLlegada.map((item) => {
                                                    return <option key={item.IdUbigeo}
                                                                value={item.CodRegion}> {item.NombreUbigeo} </option>
                                                })
                                            }
                                        </select>
                                        <select onChange={this.handleChangeProvinciaLlegada}
                                                className="input__linea col-4" name="ProvinciaLlegada"
                                                value={this.state.ProvinciaLlegada || ""}>
                                            <option value={""}>Selecccione provincia</option>
                                            {
                                                this.state.dataprovLlegada.map((item) => {
                                                    return <option key={item.IdUbigeo}
                                                                value={item.CodProvincia}> {item.NombreUbigeo} </option>
                                                })
                                            }
                                        </select>
                                        <select onChange={this.handleChangeDistritoLlegada}
                                                className="input__linea col-4" name="DistritoLlegada"
                                                value={this.state.DistritoLlegada}>
                                            <option value={null}>Selecccione distrito</option>
                                            {
                                                this.state.datadistLlegada.map((item) => {
                                                    return <option key={item.IdUbigeo} id={item.IdUbigeo}
                                                                value={item.IdUbigeo}> {item.NombreUbigeo} </option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className=" caja-fondo-blanco-m-2">
                                    <b className=" ">*Dirección de Llegada</b>
                                    <div className="input__Almacen">
                                        <input
                                            name="DireccionDeLlegada"
                                            className="input__linea"
                                            type="text"
                                            value={this.state.DireccionDeLlegada}
                                            onChange={this.handleChangeUnicos}
                                        />
                                    </div>
                                </div>
                                </> : null
                            }
                            
                            <div className="row form-group col-12">
                                <b className=" ">TRANSPORTE</b>
                            </div>

                            <div className=" caja-fondo-blanco-m-2">
                                <b className="pt-3 mb-3">Empresa transportista: </b>
                                <div className="input__Almacen">
                                    <SelectSearch
                                        isDisabled
                                        name="IdEmpresaTransportista"
                                        value={this.state.IdEmpresaTransportista}
                                        onChange={(e) => this.handleEmpresaTransportistaChange(e)}
                                        options={this.ListaEmpresaTransportista(this.state.dataEmpresasTransportistas)}
                                        className="select-search-box"
                                        placeholder="Seleccionar empresa transportista"

                                    />
                                    <div className='seccion-btn-verde-mfp'>
                                        <button onClick={() => this.setState({isOpenModalEmpresaTransportista: true})}
                                                className="ml-2 mr-2 btn-verde-mfp seccion-btn-verde-mfp"
                                                name="empresaTransportista" type="button">
                                            <span>AGREGAR</span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className=" caja-fondo-blanco-m-2">
                                <b className="pt-3 mb-3">Vehículo: </b>
                                <div className="input__Almacen">
                                    <SelectSearch
                                        isDisabled
                                        name="IdVehiculo"
                                        value={this.state.IdVehiculo}
                                        onChange={(e) => this.handleVehiculoChange(e)}
                                        options={this.ListaVehiculos(this.state.dataVehiculos.Vehiculos)}
                                        className="select-search-box"
                                        placeholder="Seleccionar Vehiculo"

                                    />
                                    <div className='seccion-btn-verde-mfp'>
                                        <button onClick={() => this.setState({isOpenModalVehiculo: true})}
                                                className="ml-2 mr-2 btn-verde-mfp seccion-btn-verde-mfp"
                                                name="docOrigen" type="button">
                                            <span>AGREGAR</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className=" caja-fondo-blanco-m-2">
                                <b className="pt-3 mb-3">Conductor </b> <br/>

                                <div className="input__Almacen">
                                    <SelectSearch
                                        name="IdTransportista"
                                        value={this.state.IdTransportista}
                                        onChange={(e) => this.handleTransportistaChange(e)}
                                        options={this.ListaTransportistas(this.state.dataTransportistas.Transportistas)}
                                        className="select-search-box"
                                        placeholder="Seleccionar Piloto"

                                    />
                                    <div className='seccion-btn-verde-mfp'>
                                        <button onClick={() => this.setState({isOpenModalTrans: true})}
                                                className="ml-2 mr-2 btn-verde-mfp "
                                                name="docOrigen" type="button">
                                            <span>AGREGAR</span>
                                        </button>
                                    </div>
                                </div>

                            </div>
                            <div className="row form-group col-12 d-flex justify-content-end no-pad">
                                <div className="" style={{display: "contents"}}>
                                    <b className="">
                                        <label align="left" className="checkbox-toggle">
                                            <div className="toggle">
                                                <input
                                                    type="checkbox"
                                                    name="Valorizacion"
                                                    className="toggle-state"
                                                    value={this.state.HabilitarCoPiloto}
                                                    onChange={this.handleChangeCheckBox}
                                                    checked={this.state.HabilitarCoPiloto}
                                                />
                                                <div className="toggle-inner">
                                                    <div className="indicator"/>
                                                </div>
                                                <div className="active-bg"/>
                                            </div>
                                        </label>
                                        Co - Conductor
                                    </b>
                                    <div hidden={!this.state.HabilitarCoPiloto} className=""
                                         style={{display: "contents"}}>
                                        <SelectSearch
                                            name="IdCoTransportista"
                                            value={this.state.IdCoTransportista}
                                            onChange={(e) => this.handleCoTransportistaChange(e)}
                                            options={this.ListaTransportistas(this.state.dataCoTransportistas)}
                                            className="select-search-box"
                                            placeholder="Seleccionar Copiloto"
                                        />
                                    </div>
                                </div>
                            </div>

                            <Modal
                                isOpen={this.state.statusModal}
                                onClose={this.handleOnCloseModal}
                                title="Datos"
                            >
                                <RegistroVentaList
                                    state={this.state}
                                    handleMonthPickerChange={this.handleMonthPickerChange}
                                    obtenerRV={this.obtenerRV}
                                />

                            </Modal>
                            <div className="row form-group col-12">
                                <b className="">PRODUCTOS</b>
                            </div>
                            <div className=" caja-fondo-blanco-m-2">

                                <b className="ml-1">*Peso aprox. de carga (Kg):</b>
                                <div className='input__Almacen'>
                                    <input type="text"
                                           onChange={this.handleChangeUnicos}
                                           name="Peso"
                                           className="input__linea "
                                           value={this.state.Peso}

                                           disabled
                                    />
                                </div>

                            </div>
                            <div className="form-group w-100">
                                <div className="">
                                    <table className="tabla-almacen table table-hover text-center">
                                        <thead className="text-center bg-dark text-white">
                                        <tr>
                                            <th>Cantidad</th>
                                            <th>Item</th>
                                            <th>Precio</th>
                                            <th>Importe</th>
                                            <th>Peso(Kg)</th>
                                            <th>Observación</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.DetallesVentaNuevo.map(item => {
                                            return (
                                                <tr key={item.IdDetalleVenta}>
                                                    <td>{item.Cantidad}</td>
                                                    <td>{item.NombreProducto}</td>
                                                    <td>{item.PrecioVenta}</td>
                                                    <td>{item.Importe}</td>
                                                    <td className="input__Almacen">
                                                        <input className="input__lineaaaa" style={{padding: 0}}
                                                               name="Peso"
                                                               value={item.Peso}
                                                               onFocus={(e) => e.target.select()}
                                                               onChange={(e) => this.handleChangePeso(e, item)}
                                                               onBlur={(e) => this.obBlurInputNumerIsEmpty(e, item)}
                                                               type="number" min={0}/>
                                                    </td>
                                                    <td>
                                                        <input className="input__linea" name={item.Observacion}
                                                               onChange={(e) => this.handleChangeObservacion(e, item)}
                                                               value={item.Observacion} type="text"/>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className=" caja-fondo-blanco-m-2">
                                <b className=" ">Comentarios</b>
                                <div className="input__Almacen">
                                    <input
                                        name="ComentarioAdicional"
                                        className="input__linea "
                                        type="text"
                                        value={this.state.ComentarioAdicional}
                                        onChange={this.handleChangeUnicos}
                                    />
                                </div>
                            </div>

                            <div className=" caja-fondo-blanco-m-2">
                                <b className=" ">Cantidad de bultos</b>
                                <div className="input__Almacen">
                                    <input
                                        name="numBultos"
                                        className="input__linea "
                                        type="number"
                                        value={this.state.numBultos}
                                        onChange={this.handleChangeUnicos}
                                        placeholder='0'
                                    />
                                </div>
                            </div>

                            {/* <div className=" caja-fondo-blanco-m-2">
                                <b className=" ">Número de contenedor (solo si es importación)</b>
                                <div className="input__Almacen">
                                    <input
                                        name="ComentarioAdicional"
                                        className="input__linea"
                                        type="text"
                                        value={this.state.numContenedor || ""}
                                        onChange={e => this.setState({numContenedor: e.target.value})}
                                    />
                                </div>
                            </div> */}
                            <div className="seccion-btn-verde-mfp mb-5">
                                <BotonCrearLetras className="ml-3" type="submit" onClick={this.newGuiaRemision}/>
                            </div>
                        </div>
                    </form>

                    <Modal title="Agregar vehículo" onClose={() => this.setState({isOpenModalVehiculo: false})}
                           isOpen={this.state.isOpenModalVehiculo}>
                        <VehiculoAddForm onSaveVehiculo={this.onSaveVehiculo}/>
                    </Modal>

                    <Modal title="Agregar transportista" onClose={() => this.setState({isOpenModalTrans: false})}
                           isOpen={this.state.isOpenModalTrans}>
                        <AddTransportistaForm onSaveTransportista={this.onSaveTransportista}/>
                    </Modal>

                    <Modal title="Agregar empresa transportista" onClose={() => this.setState({isOpenModalEmpresaTransportista: false})}
                           isOpen={this.state.isOpenModalEmpresaTransportista}>
                        <EmpresaTransportistaAddForm onSaveEmpresaTransportista={(e) => this.onSaveEmpresaTransportista(e)}/>
                    </Modal>
                </div>
            </section>
        )

    }

}

export default GuiaRemision;
