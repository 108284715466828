import React from "react"
import MovimientoAlmacenDetalle from './MovimientoAlmacenDetalle'
import NumericInput from 'react-numeric-input';
import ListaOC from '../Compras/OrdenCompraListSearch'
import Modal from '../clientes/ClienteModal';
import ModalProveedor from '../clientes/ClienteModal';

import {BotonAddItem, BotonAddProveedorDos, BotonSearchProveedorDos} from '../Plantillas/Botones'
import ProveedorNuevo from "../Proveedores/ProveedorNuevo";
import ProveedoresListaSearch from "../Proveedores/ProveedoresListaSearch";
import {decimalAdjust, ID_TIPO_MOV_ALMACEN, ID_TIPO_TRANSACCION} from "../../Global";
import Select from 'react-select'
import AsyncSelect from "react-select/async/dist/react-select.esm";
import moment from "moment";
import swal from "sweetalert";
import {notificarError, notificarMsg} from "../Almacenes/AlmacenNotify";


class MovimientoAlmacenForm extends React.Component {

    constructor(props) {
        super(props)
        this.defaultProveedor = this.props.form.IdProveedor + "-" + this.props.form.rucProveedor;
        this.state = {
            modalLOCIsOpen: false,
            loading: true,
            error: null,
            EsCompraLocal: false,
            IdOrdenCompra: null,
            OrdenCompraSucursal: "",
        }
        this.searchProducts = this.searchProducts.bind(this);
        this.validacionComprobante = this.validacionComprobante.bind(this);
        this.showModalValidarComprobante = this.showModalValidarComprobante.bind(this);
        this.asignaProveedorOrdenCompra = this.asignaProveedorOrdenCompra.bind(this);
        this.proveedores = this.props.dataListaProveedores.map(item => ({
            ...item,
            value: item.IdProveedor + "-" + item.Ruc,
            label: item.NombreComercial
        }))
        this.handleBuscarDocSucursal = this.handleBuscarDocSucursal.bind(this);
        this.handleBuscarOrdenCompraSucursal = this.handleBuscarOrdenCompraSucursal.bind(this);
        this.currentProveedor = this.proveedores.find(x => x.value === this.props.form.IdProveedor + "-" + this.props.form.rucProveedor)
        this.agregarItemsOc = this.agregarItemsOc.bind(this);
    }

    handleCloseModalLOC = (e) => {
        this.setState({
            modalLOCIsOpen: false
        })
    }

    asignaProveedorOrdenCompra(id){
        this.props.asignaProveedor(id)
    }

    handleOpenModalLOC = (e) => {
        this.setState({
            modalLOCIsOpen: true
        })
    }

    searchProducts(search) {
        const tipoDoc = this.props.form.tipoDoc;
        return new Promise((resolve, reject) => {
            fetch(`/api/ordenescompra/listprod/382?tipoDoc=${tipoDoc.Codigo}&search=${search}`)
                .then(r => r.json())
                .then(prods => resolve(prods))
                .catch(reject)
        })
    }

    async agregarItemsOc() {
        try {
            const req = await fetch(`/api/ordenescompra/detalleoc/${this.props.form.IdOrdenCompra}`);

            if (!req.ok) {
                throw new Error();
            }

            const  [, detalles]  = await req.json();

            for (const d of detalles) {
                const config = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        IdDetalleMovimientosAlmacen: "1",
                        Cantidad: d.Cantidad,
                        FechaCaducidad: null,
                        IdMovimientoAlmacen: this.props.form.IdMovimientoAlmacen,
                        IdProducto: d.IdProducto,
                        IdLote: null,
                        IdPresentacion: d.IdPresentacion,
                        IdUnidad: d.IdUnidad,
                        IdStock: null,
                        Precio: d.PrecioReferencial,
                        precioUnidadDolar: 0,
                        precioUnidadDolarSinIgv: 0,
                        Total: d.Total,
                        idTipoTransaccion: ID_TIPO_TRANSACCION.ID_RETIRO,
                        Flete: d.Flete
                    })
                }

                await fetch(`/api/movimientosalmacen/detallemovimiento/add/`, config)
            }
                        
            this.props.listaDetalleMovimientoAlmacen();
        } catch (e) {
            console.error(e)
        }
    }


    async showModalValidarComprobante() {
        try {
          // await swal({
          //       title: 'Aviso',
          //       text: `En estos momentos se empezara a validar el comprobante`,
          //       icon: 'info',
          //       closeOnEsc: true,
          //       closeOnClickOutside: false,
          //       timer: 2000
          //   }).then(function (){
          // })
            await this.validacionComprobante()
        } catch (e) {
            await swal({
                title: 'Alerta',
                text: `Problemas para validar comprobante`,
                icon: "warning",
                dangerMode: true,
                timer: 2000
            }).then(function (){
            })
            console.log("termino fallido sin Ok", e)
        }
        let agregarComprobante = await swal({
            title: 'Aviso',
            text: `¿Desea registrar el comprobante?`,
            icon: "info",
            buttons: [
                'No',
                'Si'
            ],
        })
        if (agregarComprobante) {
            await this.props.handleSubmit();
        }
    }

    async validacionComprobante() {
        const form = {...this.props.form};
        // console.log({form})
        const tipoDoc = form.tipoDoc
        // console.log({tipoDoc})
        const body =
            {
                consultaComprobante: {
                    numDoc: form.rucProveedor,
                    tipoDoc: "0"+tipoDoc.Codigo,
                    serie: form.nroSerie,
                    correlativo: form.NroDocumentoSunat,
                    fechaEmision: form.FechaMovimiento,
                    montoImporteVenta: form.total,
                }
            }
        //     ejemplo
        // const body2 =
        //     {
        //         consultaComprobante: {
        //             numDoc: "20602716423",
        //             tipoDoc: "01",
        //             serie: "FE01",
        //             correlativo: "9890",
        //             fechaEmision: "2023-11-08",
        //             montoImporteVenta: "1988.00"
        //         }
        //     }
        const opts = {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',

            }

        }
        const response = await fetch(`/api/movimientosalmacen/validar-comprobante`, opts)
        const validacionResponse = await response.json();
        if (!response.ok) {
            throw new Error(validacionResponse.message)
        }
        notificarMsg("Comprobante ha sido validado correctamente.");
        // console.log({rpta})
    }

    async handleBuscarOrdenCompraSucursal() {
        this.setState({
            modalLOCIsOpen: true
        })
    }

    async handleBuscarDocSucursal() {
        const req = await fetch(`/api/movimientosalmacen/doc-sucursal?nroDocumento=${this.props.form.DocInterno}`)

        if (!req.ok) {
            notificarError("No se ha encontrado ningún documento similar.")
            return;
        }

        const res = await req.json()

        const reqDetalles = await fetch(`/api/movimientosalmacen/detallemovimiento/list/${res.IdMovimientoAlmacen}`)

        const [, detalles] = await reqDetalles.json();
        
        const requests = detalles.map(d => {
            const payload = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    IdDetalleMovimientosAlmacen: "1",
                    Cantidad: d.Cantidad,
                    FechaCaducidad: null,
                    IdMovimientoAlmacen: this.props.form.IdMovimientoAlmacen,
                    IdProducto: d.IdProducto,
                    IdLote: null,
                    IdPresentacion: d.IdPresentacion,
                    IdUnidad: d.IdUnidad,
                    IdStock: null,
                    Precio: d.Precio,
                    precioUnidadDolar: 0,
                    precioUnidadDolarSinIgv: 0,
                    Total: d.Total,
                    idTipoTransaccion: ID_TIPO_TRANSACCION.ID_TRANSFERENCIA_ENTRE_ALMACENES,
                    Flete: d.Flete
                })
            }

            return fetch(`/api/movimientosalmacen/detallemovimiento/add/`, payload)
        });

        requests.reduce((acc, curr) => {
            return acc.then(curr).then(result => {
                return result;
            });
        }, Promise.resolve());

        await this.props.listaDetalleMovimientoAlmacen();
    }

    render() {
        let totalExoneradas = Number(this.props.form.totalExoneradas);
        let totalInafectas = Number(this.props.form.totalInafectas);
        let tmpTotal = this.props.form.vTotal - (totalExoneradas + totalInafectas)
        //console.log({propsForm: this.props})
        let base = tmpTotal / 1.18;
        let igv = base * 0.18;
        let total = this.props.form.Total;

        let precioCosto = this.props.form.Precio;
        let flete = this.props.form.Flete;
        let fletePorProducto = this.props.form.FletePorProducto
        let precioCostoIgv = precioCosto * 1.18;

        let precioUnidadDolar = this.props.form.precioUnidadDolar;
        let precioUnidadDolarIgv = this.props.form.precioIgvDolar;

        let tipoDoc = this.props.form.tipoDoc;
        let infoSerie = tipoDoc && tipoDoc.datosSerie ? tipoDoc.datosSerie.split(",") : null;

        let dataSerie = {
            serie: infoSerie && this.props.accion !== "Editar" && tipoDoc.canEditSerie ? infoSerie[1] : this.props.form.nroSerie,
            num: infoSerie && this.props.accion !== "Editar" && tipoDoc.canEditSerie ? Number(infoSerie[2]) + 1 : this.props.form.NroDocumentoSunat
        }

        const esNotaEgreso = String(this.props.form.tipoDoc?.IdTipoDocumentoSunat) == "53";

        const isEgreso = String(this.props.form.IdTipoMovimientoAlmacen) === String(ID_TIPO_MOV_ALMACEN.SALIDA)
            || String(this.props.form.IdTipoDocumentoSunat) === "52";

        return (
            <div>
                <hr width="1080px"/>
                <form>

                    <ul className="nav nav-tabs" id="pills-tab" role="tablist">
                        <li className="nav-item">
                            <a className={`nav-link ${this.props.activeItems ? '' : 'active'}`} id="pills-proveedor-tab"
                               data-toggle="pill"
                               href="#pills-proveedor" role="tab" aria-controls="pills-proveedor" aria-selected="true">
                                Datos
                                Movimiento Almacen</a>
                        </li>
                        <li className="nav-item">
                            <a className={this.props.accion === 'Editar' ? (this.props.activeItems ? "nav-link active nav-activo" : "nav-link") : "nav-link disabled"}
                               id="pills-items-tab" data-toggle="pill" href="#pills-items" role="tab"
                               aria-controls="pills-items" aria-selected="false">Lista de Items</a>
                        </li>
                    </ul>

                    <div className="tab-content container" id="pills-tabContent">
                        <div
                            className={this.props.activeItems ? 'tab-pane pt-3' : 'tab-pane fade show active pt-3'}
                            id="pills-proveedor" role="tabpanel"
                            aria-labelledby="pills-proveedor-tab">

                            <div className="form-row">
                                <div className="form-group col-12 col-sm-8">
                                    <label htmlFor="IdTipoTransaccionAlmacen"><strong>Proveedor</strong></label>
                                    <Select defaultValue={this.currentProveedor} options={this.proveedores} onChange={e => this.props.handleChangeProveedor({
                                        target: {
                                            value: e.value
                                        }
                                    })}/>
                                </div>
                                {/* <div className="form-group col-12 col-sm-8">
                                    <label htmlFor="IdTipoTransaccionAlmacen"><strong>Proveedor</strong></label>
                                    <select className="selectpicker form-control"
                                            id="IdProveedor"
                                            value={this.props.form.IdProveedor + "-" + this.props.form.rucProveedor || ""}
                                            onChange={console.log}
                                    >
                                        <option
                                            key={this.props.form.IdProveedor + "-" + this.props.form.rucProveedor}
                                            value={null}
                                        >SELECCIONAR
                                        </option>
                                        {this.props.dataListaProveedores.map(item => {
                                            const idRuc = item.IdProveedor + "-" + item.Ruc
                                            //console.log({idRuc})
                                            return (
                                                <option
                                                    key={idRuc}
                                                    value={idRuc}
                                                    title={item.NombreComercial}
                                                >{item.NombreComercial}
                                                </option>
                                            )
                                        })}
                                    </select>
                                </div> */}

                                <div className="form-group col-12 col-sm-4 d-flex justify-content-end">
                                    <BotonAddProveedorDos
                                        type="button"
                                        name="BtnAddProveedor"
                                        className="mr-2 botones-proveedor"
                                        onClick={this.props.handleClickNuevoProveedor}
                                        title="agregar proveedor"
                                    />
                                    <BotonSearchProveedorDos
                                        type="button"
                                        name="BtnAddProveedor"
                                        className="botones-proveedor"
                                        onClick={this.props.handleClickBuscarProveedor}
                                        title="buscar proveedor"
                                    />
                                </div>

                            </div>

                            <div className="form-row">
                                <div className="form-group col-12">
                                    <label htmlFor="FechaMovimiento"><strong>Fecha Movimiento</strong></label>
                                    <input type="date"
                                           value={moment(this.props.form.FechaMovimiento).format("YYYY-MM-DD")}
                                           className="form-control"
                                           onChange={this.props.handleChangeFechaMovimiento}/>
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="IdTipoMovimientoAlmacen"><strong>Tipo Movimiento</strong></label>
                                    <select className="selectpicker form-control"
                                            id="IdTipoMovimientoAlmacen"
                                            value={this.props.form.IdTipoMovimientoAlmacen}
                                            onChange={this.props.handleChangeTipoMovimiento}
                                    >
                                        {this.props.dataTipoMovimiento.map(item => {
                                            return (
                                                <option key={item.IdTipoMovimientoAlmacen}
                                                        value={item.IdTipoMovimientoAlmacen}
                                                        title={item.Tipo}>{item.DescripcionCorta}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="form-group col-12">


                                    <label htmlFor="IdTipoDocumentoSunat"><strong>Documento Asociado</strong></label>
                                    <select className="selectpicker form-control"
                                            id="IdTipoDocumentoSunat"
                                            value={this.props.form.IdTipoDocumentoSunat || ""}
                                            onChange={this.props.handleChangeTipoDocumentoSunat}
                                    >
                                        {this.props.dataTipoDocumentoSunat.map(item => {
                                            return (
                                                <option width="20px" key={item.IdTipoDocumentoSunat}
                                                        value={item.IdTipoDocumentoSunat}
                                                        title={item.Descripcion}>{`0${item.Codigo} ${item.Descripcion}`}</option>
                                            )
                                        })}
                                    </select>


                                </div>

                                <div className="form-group col-12">
                                    <label htmlFor="NroSerie"><strong>Serie</strong></label>
                                    <input name="nroSerie"
                                           id="NroSerie"
                                           className="form-control"
                                           onChange={this.props.handleChangeForm}
                                           type="text"
                                           min="1"
                                           disabled={tipoDoc ? tipoDoc.canEditSerie : true}
                                           value={dataSerie.serie}
                                    />
                                </div>

                                <div className="form-group col-12">
                                    <label htmlFor="NroDocumentoSunat"><strong>Nro Documento</strong></label>
                                    <input name="NroDocumentoSunat"
                                           id="NroDocumentoSunat"
                                           className="form-control"
                                           onChange={this.props.handleChangeForm}
                                           type="text"
                                           disabled={tipoDoc ? tipoDoc.canEditSerie : true}
                                           value={dataSerie.num}
                                    />
                                </div>
                            </div>

                            <div className="form-row">

                                <div className="form-group col-12">
                                    <label htmlFor="tipoCambio"><strong>Tipo de cambio</strong></label>
                                    <input name="tipoCambio"
                                           id="tipoCambio"
                                           className="form-control"
                                           onClick={e => e.target.select()}
                                           onChange={this.props.handleChangeTipoCambio}
                                           type="text"
                                           value={this.props.form.tipoCambio}
                                    />
                                </div>

                                {this.props.form.IdTipoDocumentoSunat == 2 || this.props.form.IdTipoDocumentoSunat == 4 || this.props.form.IdTipoDocumentoSunat == 10 ?
                                    <div className="form-group col-12">
                                        <label htmlFor="total_mov"><strong>Total</strong></label>
                                        <input name="total"
                                               id="total_mov"
                                               className="form-control"
                                               onClick={e => e.target.select()}
                                               onChange={this.props.handleChangeTotal}
                                               type="text"
                                               value={this.props.form.total}
                                        />
                                    </div>
                                    :
                                    null
                                }

                                {
                                    this.props.form.EsCompraLocal ?
                                        <div className="input-group col-12">
                                            <label><strong>Nro Orden Compra</strong></label>
                                            <div className="input-group">
                                                <input name="NroDocumentoSunat"
                                                       className="form-control"
                                                       onChange={this.props.handleChangeForm}
                                                       type="text"
                                                       value={this.props.form.NroOrdenCompra || ""}
                                                       disabled
                                                />
                                                <div className="form-group-append mb-3">
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-secondary "
                                                        onClick={this.handleOpenModalLOC}
                                                        title='buscar orden de compra'
                                                    >
                                                        <i className="fas fa-search"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                }

                            </div>


                            <div className="form-row">
                                <div className="col">
                                    <label><strong>Glosa</strong></label>
                                    <textarea type="text"
                                              name="Glosa"
                                              className="form-control"
                                              value={this.props.form.Glosa || ""}
                                              onChange={this.props.handleChangeForm}
                                    />
                                </div>
                            </div>

                        </div>

                        <div
                            className={this.props.activeItems ? 'tab-pane fade show active' : 'tab-pane'}
                            id="pills-items" role="tabpanel"
                            aria-labelledby="pills-items-tab">
                            {!esNotaEgreso && <div className="form-row mt-2">
                                <div className="col-sm-12">
                                    <label><strong>Completar con información de otra sucursal</strong></label>
                                    <div className="input-group">
                                        <input name="DocInterno"
                                                className="form-control"
                                                onChange={this.props.handleChangeForm}
                                                type="text"
                                                value={this.props.form.DocInterno || ""}
                                        />
                                        <div className="form-group-append mb-3">
                                            <button
                                                type="button"
                                                className="btn btn-outline-secondary "
                                                onClick={this.handleBuscarDocSucursal}
                                                title='Buscar documento'
                                            >
                                                <i className="fas fa-search"/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {esNotaEgreso && <div className="form-row mt-2">
                                <div className="col-sm-12">
                                    <label><strong>Orden de compra de sucursal</strong></label>
                                    <div className="input-group">
                                        <input name="OrdenCompraSucursal"
                                                className="form-control"
                                                onChange={this.props.handleChangeForm}
                                                type="text"
                                                value={this.props.form.OrdenCompraSucursal || ""}
                                        />
                                        <div className="form-group-append mb-3">
                                            <button
                                                type="button"
                                                className="btn btn-outline-secondary "
                                                onClick={this.handleOpenModalLOC}
                                                title='Buscar documento'
                                            >
                                                <i className="fas fa-search"/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            <div className="form-row">
                                <div className="col-sm-12">
                                    <label><strong>Buscar producto</strong></label>
                                    <AsyncSelect
                                        isClearable={true}
                                        onChange={this.props.handleChange_SelectSearch}
                                        value={this.props.prod}
                                        loadOptions={this.searchProducts}
                                    />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="col-12 mt-3">
                                    <label><strong>Precio x UND ($) sin IGV</strong></label>
                                    <input
                                        disabled={isEgreso}
                                        className="form-control form-control-sm"
                                        name="precioUnidadDolar"
                                        min={0}
                                        max={10000}
                                        step={1}
                                        type="number"
                                        onClick={e => e.target.select()}
                                        onChange={e => this.props.handleChangePrecioUnidadDolar(e.target.value)}
                                        value={decimalAdjust('floor', precioUnidadDolar, -6)}
                                    />
                                </div>

                                {this.props.showPrecioSolIgv ? (
                                    <div className="col-12 mt-3">
                                        <label><strong>Precio x UND ($) con IGV</strong></label>
                                        <input
                                            disabled={isEgreso}
                                            className="form-control form-control-sm"
                                            min={0}
                                            max={10000}
                                            step={1}
                                            type="number"
                                            onClick={e => e.target.select()}
                                            onChange={e => this.props.handleChangePrecioDolarIgv(e.target.value)}
                                            value={decimalAdjust('floor', precioUnidadDolarIgv, -6)}
                                        />
                                    </div>
                                ) : null}

                                <div className="col-12 mt-3">
                                    <label><strong>Precio Costo sin IGV</strong></label>
                                    <NumericInput
                                        disabled={isEgreso}
                                        className="form-control form-control-sm"
                                        name="Precio"
                                        min={0}
                                        max={10000}
                                        step={1}
                                        precision={6}
                                        onClick={e => e.target.select()}
                                        onChange={this.props.handleChangePrecio}
                                        value={precioCosto}
                                    />
                                </div>

                                {this.props.showPrecioSolIgv ? (
                                    <div className="col-12  mt-3">
                                        <label><strong>Precio costo con IGV</strong></label>
                                        <NumericInput
                                            disabled={isEgreso}
                                            className="form-control form-control-sm"
                                            min={0}
                                            max={10000}
                                            step={1}
                                            onChange={this.props.handleChangePrecioIgv}
                                            precision={6}
                                            onClick={e => e.target.select()}
                                            value={precioCostoIgv}
                                        />
                                    </div>
                                ) : null}

                                <div className="col-12 mt-3">
                                    <label><strong>Flete</strong></label>
                                    <NumericInput
                                        disabled={isEgreso}
                                        className="form-control form-control-sm"
                                        name="FletePorProducto"
                                        min={0}
                                        max={10000}
                                        step={1}
                                        precision={6}
                                        onClick={e => e.target.select()}
                                        onChange={this.props.handleChangeFlete}
                                        value={this.props.form.FletePorProducto}
                                    />
                                </div>

                                <div className="col-12 mt-3">
                                    <label><strong>Cantidad</strong></label>
                                    <NumericInput
                                        className="form-control form-control-sm"
                                        name="Cantidad"
                                        min={0}
                                        max={10000}
                                        step={1}
                                        precision={3}
                                        onChange={this.props.handleChangeCantidad}
                                        onClick={e => e.target.select()}
                                        value={this.props.form.Cantidad}
                                    />
                                </div>

                            </div>

                            <div className="form-row mt-3">
                                <div className="form-group col-12">

                                    <label htmlFor="IdTipoTransaccionAlmacen"><strong>Tipo Transacción</strong></label>
                                    <select className="selectpicker form-control"
                                            id="IdTipoTransaccionAlmacen"
                                            value={this.props.form.IdTipoTransaccionAlmacen}
                                            onChange={this.props.handleChangeTipoTransaccion}
                                    >
                                        <option key={"opt_null_tipo_transaccion"} value="">Seleccionar</option>
                                        {this.props.dataTipoTransaccion.map(item => {
                                            return (
                                                <option key={item.IdTipoTransaccionAlmacen}
                                                        value={item.IdTipoTransaccionAlmacen}
                                                        title={item.Descripcion}>{item.DescripcionCorta}</option>
                                            )
                                        })}
                                    </select>

                                </div>

                                <div className="col-sm mt-4 d-flex justify-content-end">
                                    <BotonAddItem
                                        title="agregar item"
                                        type="button"
                                        onClick={this.props.handleAgregarItem}
                                        disabled={!this.props.form.IdMovimientoAlmacen}
                                    />
                                </div>
                            </div>

                            <div className="form-row mt-3">
                                <MovimientoAlmacenDetalle
                                    data={this.props.dataDetalleMovimientoAlmacen}
                                    handleEliminarItem={this.props.handleEliminarItem}
                                    handleAddQuantityItem={this.props.handleAddQuantityItem}
                                />
                            </div>

                            <div className="form-row mt-1">
                                <div className="col">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-around p-2 contenido-card">
                                                <p> {`Base: ${decimalAdjust('floor', base, -1)}`} </p>
                                                <p> {`Total Exoneradas: ${totalExoneradas}`} </p>
                                                <p> {`Total Flete: ${decimalAdjust('floor', flete, -2)}`} </p>
                                                <p> {`Total inafectas: ${totalInafectas}`} </p>
                                                <p> {`IGV: ${decimalAdjust('floor', igv, -2)}`} </p>
                                                <p> {`Total: ${decimalAdjust('floor', total, -2)}`} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="form-row justify-content-center m-3">
                        <button type="button" className="btn btn-secondary mr-2 mt-3"
                                onClick={this.props.handleCloseModalMA}>Cancelar
                        </button>
                        <button type="button" className="btn btn-info mt-3"
                                onClick={this.props.accion == 'Nuevo' ? this.showModalValidarComprobante : this.props.handleSubmit}>{this.props.accion == 'Nuevo' ? 'Validar Comprobante' : 'Actualizar Comprobante'}</button>
                    </div>

                </form>

                <Modal isOpen={this.state.modalLOCIsOpen} onClose={this.handleCloseModalLOC} width='900px'
                       title="Lista Ordenes de Compra">
                    <ListaOC
                        paraNotaEgreso={esNotaEgreso}
                        onClose={this.handleCloseModalLOC}
                        asignarDatosProveedor={this.asignaProveedorOrdenCompra}
                        agregarItems={this.agregarItemsOc}
                        form={this.props.form}
                    />
                </Modal>

                {
                    this.props.form.accion === 'NuevoProveedor' ?
                        <ModalProveedor isOpen={this.props.form.modalProveedorIsOpen}
                                        onClose={this.props.handleCloseModalProv}
                                        title="NUEVO PROVEEDOR">
                            <ProveedorNuevo
                                onClose={this.props.handleCloseModalProv}
                                NumeroDocumento={this.props.form.Ruc}
                                AsignarValorDoc={this.props.AsignarValorDoc}
                                sw='oc_nuevo'
                            />
                        </ModalProveedor> :

                        <ModalProveedor isOpen={this.props.form.modalProveedorIsOpen}
                                        onClose={this.props.handleCloseModalProv}
                                        title="BUSCAR PROVEEDOR">
                            <ProveedoresListaSearch
                                onClose={this.props.handleCloseModalProv}
                                form={this.props.form}
                            />
                        </ModalProveedor>
                }

            </div>
        )
    }
}

export default MovimientoAlmacenForm
