import React from 'react';
import { decimalAdjust } from '../../Global';

class PreventaModalDescuentos extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            descuento: 0,
        }

        this.onChangeDescuento = this.onChangeDescuento.bind(this);
        this.fetchDescuentoPredefinido = this.fetchDescuentoPredefinido.bind(this);
        this.calculateDiscountFromProps = this.calculateDiscountFromProps.bind(this);
    }

    async componentDidMount() {
        let descuento = this.calculateDiscountFromProps();

        if (descuento === 0) {
            descuento = await this.fetchDescuentoPredefinido();
        }

        this.onChangeDescuento(descuento)

        this.setState({ descuento })
    }

    componentWillUnmount() {
        this.props.onBlurDescuento(null, this.props.ItemDescuento)
    }
    
    calculateDiscountFromProps() {
        const item = this.props.ItemDescuento;
        const { Descuento, Cantidad } = item;

        if (Descuento == 0) {
            return 0;
        }

        return decimalAdjust('floor', Descuento / Cantidad, -2)
    }

    async fetchDescuentoPredefinido() {
        const idPresentacion = this.props.ItemDescuento.IdPresentacion;

        const req = await fetch(`/api/presentaciones/${idPresentacion}/descuento-predefinido`);

        if (!req.ok) {
            return 0;
        }

        const { Descuento } = await req.json();

        return Descuento;
    }

    onChangeDescuento(val) {
        this.setState({
            descuento: val
        }, () => {
            this.props.DescuentoChange({
                target: { value: val * this.props.ItemDescuento.Cantidad }
            }, this.props.ItemDescuento);
        })
    }

    render() {
        const item = this.props.ItemDescuento;
        const afectsIgv = this.props.afectsIgv;

        return (
            <div className="m-3">
                <div className="row form-group col-12">
                    {this.props.ItemDescuento.descripcion}
                </div>
                <div className="row">
                    <div className="col-12 col-md-5 form-group">
                        <label>{`Dscto X ${item.unidadMedida}`}</label>
                        <input
                            type="number"
                            readOnly={this.props.load}
                            disabled={this.props.load}
                            step="1"
                            min="0"
                            onClick={e => e.target.select()}
                            className='form-control'
                            value={this.state.descuento}
                            onChange={e => this.onChangeDescuento(e.target.value)}
                            onBlur={e => this.props.onBlurDescuento(e, this.props.ItemDescuento)}
                        />
                    </div>

                    <div className="col-12 col-md-3 form-group">
                        <label>Cantidad </label>
                        <input
                            type="number"
                            className='form-control'
                            readOnly={true}
                            disabled={true}
                            value={item.Cantidad}
                        />
                    </div>

                    <div className="col-12 col-md-4 form-group">
                        <label>Total dscto.</label>
                        <input
                            type="number"
                            className='form-control'
                            readOnly={true}
                            disabled={true}
                            value={this.props.ItemDescuento.Descuento}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-12">
                        <label className="mr-2 mt-1" htmlFor="customCheckDescuento">¿Gratuito?</label>

                        <select className="form-control"
                                value={afectsIgv.find(i => String(i.IdAfectacionIgv === String(item.IdAfectacionIgv))) ? item.IdAfectacionIgv : "-1"}
                                onChange={e => this.props.onChangeCheckBoxGratuito(e, this.props.ItemDescuento)}
                                disabled={this.props.load}>
                                <option key={"afec-igv--1"} value="-1">No</option>
                                {afectsIgv.map(a => <option key={`afec-igv-${a.IdAfectacionIgv}`} value={a.IdAfectacionIgv}>{a.Descripcion.replace('Gravado', '')}</option>)}
                        </select>
                    </div>
                </div>
            </div>
        )
    }
}

export default PreventaModalDescuentos
