import React from "react";
import DocTypeSelector from "./DocTypeSelector";
import ClientPicker from "./ClientPicker";
import InvoiceInfoInput from "./InvoiceInfoInput";
import ProductPicker from "./ProductPicker";
import DetallesProductoPreventa from "../Preventas/DetallesProductoPreventa";
import Modal from "../Modal";
import PreventaModalDescuentos from "../Preventas/PreventaModalDescuentos";
import {BOLETA, calcTotal, VALE, FACTURA, NUMBER_KEYS, PRECIO_COSTO} from "../../Global";
import {
    FindPrecioEspecial,
    FindPrecioFamiliar,
    FindPrecioMenor,
    FindPrecioPorMayor,
    GetPrecioCosto,
    getPrecioPorMayor
} from "../Preventas/PreciosPreventa";
import $ from "jquery";
import _ from "lodash";
import {defaultGetHeaders, pfetch} from "../../helpers/wrappers";
import {notificarError} from "../Almacenes/AlmacenNotify";


export default class PreInvoiceSubForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            payload: {detalles: []},
            afectsIgv: [],
            isLoadingPreventa: false,
            isLoadingDetails: false,
            descuentoCliente:0,
        }
        this.onSecretKeyPress = this.onSecretKeyPress.bind(this)
        this.onSelectItem = this.onSelectItem.bind(this)
        this.onDeleteItem = this.onDeleteItem.bind(this)
        this.onChangeCantidad = this.onChangeCantidad.bind(this)
        this.onPriceChange = this.onPriceChange.bind(this)
        this.onSubstractQuantity = this.onSubstractQuantity.bind(this)
        this.onAddQuantity = this.onAddQuantity.bind(this)
        this.onShowModalDescuento = this.onShowModalDescuento.bind(this)
        this.onChangeDiscount = this.onChangeDiscount.bind(this)
        this.onChangeAfectFree = this.onChangeAfectFree.bind(this)
        this.onChangeTotal = this.onChangeTotal.bind(this)
        this.onSelectPrice = this.onSelectPrice.bind(this)
        this.getDataCotizacion = this.getDataCotizacion.bind(this)
        this.verifyPredefinedDiscount = this.verifyPredefinedDiscount.bind(this);
      
        this.showDecrementPriceError = _.debounce(() => {
            const items = [...this.state.payload.detalles];

            for (let i = 0; i < items.length; i++) {
                const item = items[i];

                if ('priceIsOk' in item && !item.priceIsOk) {
                    notificarError(`Hay un error! El precio no puede ser inferior al precio de venta original. Posición ${i + 1}`)
                    break;
                }
            }
        }, 500)
    }

    componentDidMount() {
        if (this.props.conf && 'defaultSellDocument' in this.props.conf) {
            const defaultSellDocument = this.props.conf.defaultSellDocument;
            
            const _docs = {
                [FACTURA]: {key: FACTURA, name: "Factura"},
                [BOLETA]: {key: BOLETA, name: "Boleta"},
                [VALE]: {key: VALE, name: "Vale"}
            }

            this.setState({ payload: { ...this.state.payload, _docType: _docs[defaultSellDocument], IdTipoDocumentoSunat: defaultSellDocument }})
        }

        if (!this.props.preventaId) {
            return
        }

        const isNumber = !_.isNaN(Number(this.props.preventaId))
        let urlPreventa = "/api/gestionPreventas/preventa/"
        let urlDetalle = "/api/gestionPreventas/detallepreventa/"
        let code = this.props.preventaId
        let type = "p"
        if (!isNumber) {
            let isStringCode = this.props.preventaId.includes("c-")
            code = isStringCode ? this.props.preventaId.split("-")[1] : ""
            urlDetalle = "/api/cotizaciones/detalles/"
            type = "c"
        }
        if (type == "p") {
            this.fetchPreventa(code, urlPreventa, type)
        }
        this.fetchDetallesPreventa(code, urlDetalle, type)
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        //console.log({prevProps,prevState})
        //console.log({antiguo:prevState.payload._client , nuevo: this.state.payload._client})
        if( prevState.payload._client != this.state.payload._client){
            const descuentoCliente = this.state.payload._client ? this.state.payload._client.DescuentoPersonal : 0;
            const descuentoPersonal = descuentoCliente? descuentoCliente : 0;
            //console.log({descuentoPersonal})
            this.setState({descuentoCliente: descuentoPersonal})
            let items = [...this.state.payload.detalles];
            this.updateState(items)
        }
    }
    fetchPreventa(id, url) {
        pfetch({
            fetch: {
                url: url + id,
                method: "GET",
                headers: defaultGetHeaders()
            },
            before: () => this.setState({isLoadingPreventa: true}),
            then: (d) => {
                const preInvoice = d.respuesta[0]
                this.setState((state) => {
                    return {
                        payload: {
                            ...state.payload,
                            ...preInvoice,
                        }
                    }
                }, this.onChange)
            },
            finally: () => this.setState({isLoadingPreventa: false})
        })
    }

    getDataCotizacion(data) {
        const detalle = data.dets
        const cotizacion = data.cot

        this.setState((state) => {
            return {
                payload: {
                    ...state.payload,
                    ...cotizacion,
                }
            }
        })
        return detalle.map(d => {
            return {
                ...d,
                IdStock: d.idStock,
                Cantidad: d.cantidad,
                IdPresentacion: d.idPresentacion,
                PrecioVenta: d.precioVenta,
                PrecioReferencial: d.precioVenta,
                TipoCambio: d.tipoCambio,
                IdAfectacionIgv: d.idAfectacionIgv,
                ValorUnitario: d.valorUnitario,
                Total: d.total,
                Descuento: 0,
                Gratuito: 0
            }
        })

    }

    fetchDetallesPreventa(id, url, type) {
        pfetch({
            fetch: {
                url: url + id,
                method: "GET",
                headers: defaultGetHeaders()
            },
            before: () => this.setState({isLoadingDetails: true}),
            then: (data) => {
                const detalle = type == "c" ? this.getDataCotizacion(data) : data.respuesta
                this.updateState(detalle.map(d => {
                    return {
                        ...d,
                        oldPrecios: [],
                        oldCants: [],
                        PrecioCosto: d.precios.find(p => p.IdPrecioPlantilla === PRECIO_COSTO).Precio
                    }
                }))
            },
            finally: () => this.setState({isLoadingDetails: false})
        })

    }

    onSecretKeyPress(e, item) {
        if (e.ctrlKey && e.shiftKey && e.which === NUMBER_KEYS.THREE_NUMBER_KEY)
            this._onChangePrice(item, item.precioMayor.Precio, "Mayor")
    }

    onSelectPrice(e, item) {
        this._onChangePrice(item, e.target.value, e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text)
    }

    onChangeCantidad(e, item) {
        const items = [...this.state.payload.detalles]
        const i = items.indexOfObject(item, 'IdPresentacion')
        const val = e.target.value;
        items[i].Cantidad = items[i].IdUnidad === 58 || items[i].IdUnidad === 59 ? parseInt(val) : val;
        FindPrecioPorMayor([], items[i])
        items[i].Total = calcTotal(items[i]) - items[i].Descuento
        this.updateState(items)
    }

    onPriceChange(e, item) {
        const btnGenerar = document.querySelector('button.generar__boton.btn.btn-success.boton-generar-resp.letra-total')

        if (!this.props.conf.canUpdatePrice && this.props.conf.canIncrementProductPrice) {
            const items = [...this.state.payload.detalles]
            const i = items.indexOfObject(item, 'IdPresentacion');
            const nuevoPrecio = e.target.value;

            items[i].priceIsOk = +nuevoPrecio >= +items[i].precio;

            if (!items[i].priceIsOk) {
                this.showDecrementPriceError();
            }
        }

        const items = [...this.state.payload.detalles]

        btnGenerar.removeAttribute("disabled")

        for (let item of items) {
            if ('priceIsOk' in item && !item.priceIsOk) {
                btnGenerar.setAttribute("disabled", "true");
                break;
            }
        }

        const val = e.target.value;
        const i = items.indexOfObject(item, 'IdPresentacion');
        items[i].oldPrecios.push(items[i].PrecioVenta);
        items[i].PrecioVenta = val
        items[i].Total = calcTotal(items[i]) - items[i].Descuento
        this.updateState(items)
    }

    onAddQuantity(item) {
        const items = [...this.state.payload.detalles]
        const i = items.indexOfObject(item, 'IdPresentacion')
        const oldQuantity = items[i].Cantidad;
        const oldDescuento = items[i].Descuento;
        const perUnitDescuento = oldDescuento / oldQuantity;
        items[i].Cantidad++
        items[i].Descuento = perUnitDescuento * items[i].Cantidad;
        items[i].oldCants.push(items[i].Cantidad)
        FindPrecioPorMayor([], items[i])
        items[i].Total = calcTotal(items[i]) - items[i].Descuento
        this.updateState(items)
    }

    onSubstractQuantity(item) {
        const items = [...this.state.payload.detalles]
        const i = items.indexOfObject(item, 'IdPresentacion')
        const oldQuantity = items[i].Cantidad;
        const oldDescuento = items[i].Descuento;
        const perUnitDescuento = oldDescuento / oldQuantity;
        items[i].Cantidad--

        if (items[i].Cantidad <= 0) {
            items[i].Descuento = 0;
            items[i].Total = 0;
        } else {
            items[i].Descuento = perUnitDescuento * items[i].Cantidad;
            items[i].oldCants.push(items[i].Cantidad)
            FindPrecioPorMayor([], items[i])
            items[i].Total = calcTotal(items[i]) - items[i].Descuento
        }

        this.updateState(items)
    }

    onDeleteItem(item) {
        let items = [...this.state.payload.detalles];
        items.splice(items.indexOfObject(item, 'IdPresentacion'), 1)
        this.updateState(items)
    }

    onChangeDiscount(e, item, save = false) {
        const items = [...this.state.payload.detalles]
        const i = items.indexOfObject(item, 'IdPresentacion')
        const val = e.target.value
        if (Number(items[i].Gratuito) === 0) {
            items[i].Descuento = val
            if (save) {
                items[i].DescuentoGuardado = val;
            }
            items[i].Total = calcTotal(items[i]) - items[i].Descuento
            this.updateState(items, {selectedItem: items[i]})
        }
    }

    onChangeTotal(e, item) {
        const items = [...this.state.payload.detalles]
        const i = items.indexOfObject(item, 'IdPresentacion')
        const val = e.target.value;

        items[i].Cantidad = val / items[i].PrecioVenta
        items[i].Total = val
        items[i].Descuento = 0
        this.updateState(items)
    }

    _onChangePrice(item, val, textPrice) {
        let items = [...this.state.payload.detalles]
        const i = items.indexOfObject(item, 'IdPresentacion')
        let foundedItem = items[i];
        const precio = foundedItem.precios.find(p => p.NombrePrecio.includes(textPrice));

        foundedItem.Precio = precio ? precio.Precio : 0
        foundedItem.PrecioVenta = precio ? precio.Precio : 0
        foundedItem.ValorUnitario = precio ? precio.ValorUnitario : 0;
        foundedItem.Total = calcTotal({Cantidad: items[i].Cantidad, PrecioVenta: foundedItem.PrecioVenta})
        foundedItem.PrecioReferencial = parseFloat(foundedItem.PrecioVenta)

        this.updateState(items)
    }

    onSelectItem(item, descuentoPredefinido = null, cantidad = 1) {
        $("#InputBuscarProd").focus();
        let items = [...this.state.payload.detalles];
        const i = items.indexOfObject(item, 'IdPresentacion')

        if (i !== -1) {
            const oldQuantity = items[i].Cantidad;
            const oldDescuento = items[i].Descuento;
            const perUnitDescuento = oldDescuento / oldQuantity;
            items[i].Cantidad++;
            items[i].Descuento = perUnitDescuento * items[i].Cantidad;
            FindPrecioPorMayor([], items[i])
            items[i].Total = calcTotal(items[i]) - items[i].Descuento
        } else {
            FindPrecioPorMayor([], item)
            try {
                const descuento = item.Descuento + (item.PrecioVenta * this.state.descuentoCliente/100)
                item.Descuento = item.PrecioVenta < descuento? 0 : descuento;
            }catch (e){
                // item.Descuento = item.Descuento + (item.PrecioVenta * descuentoPersonal/100)
                console.log({e})
            }

            const detail = {
                ...item,
                Cantidad: cantidad,
                Descuento: !!descuentoPredefinido ? descuentoPredefinido * cantidad : item.Descuento,
                PrecioVenta: item.PrecioVenta,
                PrecioEspecial: FindPrecioEspecial([], item),
                PrecioFamiliar: FindPrecioFamiliar([], item),
                PrecioCosto: GetPrecioCosto([], item),
                PrecioMenor: FindPrecioMenor([], item),
                precioMayor: getPrecioPorMayor([], item),
                checked: _.isNumber(item.checked) ? item.checked : 0,
                oldPrecios: [item.PrecioVenta],
                initialAfectGrat: item.IdAfectacionIgv,
                oldCants: [1],
                NombreProducto: item.descripcion
            }

            detail.Total = calcTotal(detail) - detail.Descuento

            const detailHandler = {
                set(target, property, value) {
                    if (property == 'Cantidad') {
                        const cantidad = +value;
                        let perUnitDescuento = 'DescuentoGuardado' in target ? target['DescuentoGuardado'] : 0;

                        if (cantidad > 0) {
                            const oldQuantity = target.Cantidad;

                            if (oldQuantity > 0) {
                                const oldDescuento = target.Descuento;
                                perUnitDescuento = oldDescuento / oldQuantity;
                            }
                        }

                        target[property] = cantidad;
                        target["Descuento"] = cantidad * perUnitDescuento;
                        target['Total'] = calcTotal(target) - target['Descuento'];
                    } else {
                        target[property] = value;
                    }

                    return true;
                }
            }

            const detailProxy = new Proxy(detail, detailHandler)

            items.push(detailProxy)
        }
        
        this.updateState(items);

        if (i < 0) {
            if (item.Descuento === 0){
                this.verifyPredefinedDiscount(item);
            }
        }
    }

    async verifyPredefinedDiscount(item) {
        const idPresentacion = item.IdPresentacion;

        const req = await fetch(`/api/presentaciones/${idPresentacion}/descuento-predefinido`);

        if (!req.ok) {
            return;
        }

        const data = await req.json();

        this.onChangeDiscount({
            target: { value: data.Descuento }
        }, item, true);
    }

    updateState(items, additional = {}) {
        this.setState((state, props) => {
            return {
                payload: {
                    ...state.payload,
                    detalles: items
                },
                ...additional
            }
        }, this.onChange)
    }

    onShowModalDescuento(item) {
        const permisoDescuento = this.props.permisosDescuento;
        const codigoValido = this.props.codigoValido;
        //this.state.codigoValido
        if(permisoDescuento && codigoValido || permisoDescuento == false){
            this.setState({showDiscountModal: true, selectedItem: item})
            this.fetchAfects(item.IdGrupoClasificacionIgv)
            return
        }
        this.props.showModalPermisosDescuento();

    }

    fetchAfects(idAfect) {
        pfetch({
            fetch: {
                url: `/api/preVentas/free-afects?idGrupoIgv=${idAfect}`,
                headers: defaultGetHeaders()
            },
            before: () => this.setState({isLoading: true}),
            then: (d) => this.setState({afectsIgv: d}),
            catch: () => notificarError("Ha ocurrido un error al momento de extraer las afectaciones gratuitas"),
            finally: () => this.setState({isLoading: false})
        })
    }

    onChangeAfectFree(e, item) {
        let items = [...this.state.payload.detalles];
        let i = items.indexOfObject(item, 'IdPresentacion');
        const val = e.target.value

        const checked = String(val) !== "-1";
        items[i].checked = checked
        items[i].Gratuito = checked ? 1 : 0

        if (checked) {
            items[i].Descuento = 0
            items[i].IdAfectacionIgv = val
        } else
            items[i].IdAfectacionIgv = items[i].initialAfectGrat

        this.updateState(items)
    }

    onChange() {
        //console.log({statebefore: this.state})
        this.props.onChange({...this.state.payload})
    }

    render() {
        const {conf} = this.props
        const {payload, afectsIgv} = this.state

        return (
            <>
                <div className="encabezado col-12 col-sm-6 no-pad h-100" style={{position: "relative"}}>

                    <DocTypeSelector
                        hideDocKeys={this.props.hideDocKeys}
                        value={payload.IdTipoDocumentoSunat ? payload.IdTipoDocumentoSunat : BOLETA}
                        onChange={d => {
                            this.setState((state, props) => {
                                return {
                                    payload: {
                                        ...state.payload,
                                        _docType: d,
                                        IdTipoDocumentoSunat: d ? d.key : d,
                                    }
                                }
                            }, this.onChange)
                        }}/>

                    <div className="preventa__cliente mt-1 mb-1" style={{display: "block"}}>
                        <div className="row contenedor-muestra-clientes">
                            <div className="col-12 col-md-8 mb-1" style={{zIndex: "99"}}>
                                <ClientPicker defaultClient={payload.NroTipoDocumento} onSelectClient={c => {
                                    this.setState((state, props) => {
                                        return {
                                            payload: {
                                                ...state.payload,
                                                IdTipoDocumentoSunat: c.NroTipoDocumento.length === 11 ? FACTURA : state.payload.IdTipoDocumentoSunat,
                                                _client: c
                                            }
                                        }
                                    }, this.onChange)
                                }}/>
                            </div>
                            <div className="preventa__cliente2 botones-desk muestra-clientes">
                                {payload._client ? `${payload._client.RazonSocial}` : "CLIENTE NO REGISTRADO"}
                            </div>
                            <div className="preventa__cliente2 botones-resp">
                                {payload._client ? `${payload._client.RazonSocial}` : "CLIENTE NO REGISTRADO"}
                            </div>
                            <div className="col-12 col-md-3" style={{zIndex: "99"}}>
                                <InvoiceInfoInput defaultText={payload.Alias} onChangeInfo={e => {
                                    this.setState((state, props) => {
                                        return {
                                            payload: {
                                                ...state.payload,
                                                alias: e
                                            }
                                        }
                                    }, this.onChange)
                                }} placeholder={conf.useAliasAsOther ? "Placa (opcional)" : "Alias - Placa"}/>
                            </div>
                        </div>
                    </div>

                    <ProductPicker
                        showSwitchStock={this.props.showSwitchStock}
                        showDefaultColor={this.props.showDefaultColor}
                        stockKey={this.props.stockKey}
                        prodsUrl={this.props.prodsUrl} onSelectItem={this.onSelectItem}
                        cleanSearch={conf.cleanableSearch}
                        detalles={this.state.payload.detalles}
                    />

                </div>

                <div className="preventa__container_data col-12 col-sm-6 no-pad">

                    {/* <div className="preventa__cliente2 botones-desk">
                        {payload._client ? `${payload._client.RazonSocial}` : "CLIENTE NO REGISTRADO"}
                    </div> */}

                    <DetallesProductoPreventa
                        defaultBgInProductDetail={this.props.defaultBgInProductDetail}
                        load={this.state.isLoading}
                        detalles={payload.detalles}
                        onSecretKeyPress={this.onSecretKeyPress}
                        venderConListaPrecios={Number(conf.canSeePrices)}
                        incrementarPrecioProd={Boolean(conf.canIncrementProductPrice)}
                        PrecioChange={this.onSelectPrice}
                        InputCantidadDetalleChange={this.onChangeCantidad}
                        InputPrecioVentaChange={this.onPriceChange}
                        RestarCantidadDet={this.onSubstractQuantity}
                        AgregarCantidadDet={this.onAddQuantity}
                        RemoveProductoInList={this.onDeleteItem}
                        handleOpenModalDescuento={this.onShowModalDescuento}
                        onBlurInputCantidad={() => {
                        }}
                        descuentoPorCliente = {this.state.descuentoCliente}
                        onBlurPrecioVenta={() => {
                        }}
                        canUpdatePrice={conf.canUpdatePrice}
                        canUpdateTotal={conf.canUpdateTotal}
                        onChangeTotal={this.onChangeTotal}
                        onBlurTotal={() => {
                        }}
                    />

                </div>

                <Modal title="Realizar descuento" isOpen={this.state.showDiscountModal}
                       onClose={() => this.setState({showDiscountModal: false})}>
                    {this.state.isLoading ?
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border" role="status"/>
                        </div> :
                        <PreventaModalDescuentos
                            load={this.state.isLoading}
                            ItemDescuento={this.state.selectedItem}
                            DescuentoChange={this.onChangeDiscount}
                            onBlurDescuento={() => {
                            }}
                            afectsIgv={afectsIgv}
                            onChangeCheckBoxGratuito={this.onChangeAfectFree}
                        />
                    }
                </Modal>
            </>
        );
    }
}
