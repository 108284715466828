import React from "react";
import StockHistoricoUI from "./StockHistoricoUI";
import PageLoading from "../PageLoading";
import PageError from "../PageError";


class StockHistorico extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productos: [],
            isLoading: false,
            error: null,
            tableConfig: {
                pageSize: 50,
                sorted: false,
                filtered: false,
                isLoading: true,
                pages: 1,
            },
            filterParams: {},
        };
    }

    setTableState(newTableState) {
        this.setState((localState, props) => {
            return {
                table: {
                    ...localState.table,
                    ...newTableState,
                }
            }
        })
    }

    render() {
        const {isLoading, error} = this.state;
        if (isLoading) {
            return <PageLoading/>;
        }
        if (error) {
            return <PageError error={this.state.error}/>;
        }
        return (
            <div id="productosContainer">
                <StockHistoricoUI
                    tableConfig={this.state.tableConfig}
                    productos={this.state.productos}
                />
            </div>
        );
    }
}

export default StockHistorico;
