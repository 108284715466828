import React from "react";
import {TIPO_STOCK} from "../../Global";
import {defaultGetHeaders, pfetch} from "../../helpers/wrappers";
import {setPreciosYDescuento} from "../../helpers/calcularVenta";
import {notificarError} from "../Almacenes/AlmacenNotify";
import ListaProductosPreventa from "../Preventas/ListaProductosPreventa";
import MagellanListener, { KILOGRAMO_UNIT_DESCRIPTION } from "../../helpers/MagellanListener";
import CombosService from "../../services/CombosService";

export default class ProductPicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            search: "",
            prods: [],
            isStockGood: true,
            magellanListenerIsAvailable: false,
            combos: [],
        }
        this.timer = null
        this.combosService = new CombosService();
        this.onChangeOtherStock = this.onChangeOtherStock.bind(this)
        this.onChangeSearch = this.onChangeSearch.bind(this)
        this.onClickItem = this.onClickItem.bind(this)
        this.reloadProds = this.reloadProds.bind(this)
        this.onSubmitSearch = this.onSubmitSearch.bind(this)
        this.onToggleDetail = this.onToggleDetail.bind(this)
        this.fetchCombos = this.fetchCombos.bind(this);
    }

    componentDidMount() {
        this.fetchProds();
        this.fetchCombos();
        MagellanListener.addSubscriber(this);
    }

    async fetchCombos() {
        try {
            const combos = await this.combosService.fetchAll();
            this.setState({ combos });
        } catch (e) {
            console.error(e);
        }
    }

    onMagellanChangeAvailability(availability) {
        this.setState({ magellanListenerIsAvailable: availability });
    }

    onRecieveCode(code) {
        this.setState({search: code}, this.onSubmitSearch.bind(this, { preventDefault: () => null }))
    }

    onClickItem(e, descuentoPredefinido = null, cantidad = 1) {
        const cleanSearch = this.props.cleanSearch
        if (cleanSearch && this.state.search && this.state.search.length) {
            this.onChangeSearch({target: {value: ""}})
            this.fetchProds(this.state.isStockGood ? TIPO_STOCK.CON_COMPROBANTE : TIPO_STOCK.SIN_COMPROBANTE, '')
        }
        this.props.onSelectItem({...e}, descuentoPredefinido, cantidad)

        if (this.state.magellanListenerIsAvailable && e.unidadMedida === KILOGRAMO_UNIT_DESCRIPTION) {
            const alreadyExists = this.props.detalles.some(p => p.IdProducto === e.IdProducto)
            MagellanListener.requestWeight(e.IdProducto, alreadyExists);
        }
    }

    onChangeSearch(e) {
        const val = e.target.value
        this.setState({search: val})
    }

    onSubmitSearch(e) {
        e.preventDefault();
        const tipoStock = this.state.isStockGood ? TIPO_STOCK.CON_COMPROBANTE : TIPO_STOCK.SIN_COMPROBANTE;
        this.fetchProds(tipoStock, this.state.search, () => {
            if (this.state.prods.length === 1)
                this.onClickItem({...this.state.prods[0]})
        })
    }

    fetchProds(idTipoStock = TIPO_STOCK.CON_COMPROBANTE, query = '', callback = null) {
        const url = this.props.prodsUrl ? this.props.prodsUrl.replace("$", query) : `/api/preVentas/?search=${query}&idTipoStock=${idTipoStock}`
        pfetch({
                fetch: {
                    url: url,
                    method: "GET",
                    headers: defaultGetHeaders()
                },
                before: () => this.setState({isLoading: true}),
                then: p => this._setProds(p, callback),
                error: () => notificarError("No se pudo cargar los productos"),
                finally: () => this.setState({isLoading: false})
            }
        )
    }

    _setProds(prods, callback = null) {
        const newProds = prods.productos.map(d => {
            const itemCalculado = setPreciosYDescuento(d)
            return {
                ...d,
                _showDetail: false,
                Precio: itemCalculado.PrecioVenta,
                PrecioReferencial: itemCalculado.PrecioVenta,
                Gratuito: parseInt(d.Gratuito)
            }
        })
        this.setState({prods: newProds}, callback)
    }

    onChangeOtherStock(e) {
        const checked = e.target.checked;
        this.setState({isStockGood: checked})
        this.fetchProds(checked ? TIPO_STOCK.CON_COMPROBANTE : TIPO_STOCK.SIN_COMPROBANTE)
    }

    reloadProds() {
        const tipoStock = this.state.isStockGood ? TIPO_STOCK.CON_COMPROBANTE : TIPO_STOCK.SIN_COMPROBANTE;
        const search = this.state.search
        this.fetchProds(tipoStock, search)
    }

    onToggleDetail(event, index) {
        event.preventDefault()

        const newProds = [...this.state.prods]
        newProds[index]._showDetail = !newProds[index]._showDetail
        this.setState({ prods: newProds })
    }

    render() {
        return (
            <>
                <div className="PreVBuscarProducto row input-producto">
                    <div className="w-100 col-12 mt-1 mb-1 botones-desk pad-15 d-flex justify-content-end switch-comprobante">
                        {
                            this.props.showSwitchStock && (
                                <>
                                    <label className="checkbox-togglePreventa no-pad" title="Stock con Comprobante">
                                        <div className="togglePreventa">
                                            <input
                                                type="checkbox"
                                                name="filtroTipoStock"
                                                className="toggle-state"
                                                onChange={this.onChangeOtherStock}
                                                checked={this.state.isStockGood}
                                                disabled={this.state.isLoading}
                                            />
                                            <div className="togglePreventa-inner">
                                                <div className="indicator" />
                                            </div>
                                            <div className="active-bg" />
                                        </div>
                                    </label>
                                </>
                            )
                        }
                    </div>
                    <div className="w-100 col-12 mt-1">
                        <form style={{ display: "contents" }} onSubmit={this.onSubmitSearch}>
                            <input
                                type="text"
                                placeholder="Buscar Producto"
                                className=" mr-2 w-100
                                DocProductoPreventa borde-15"
                                value={this.state.search}
                                onChange={this.onChangeSearch}
                            />
                        </form>
                    </div>
                    <div className="w-100 d-flex col-12 col-md-3 mt-3 botones-resp">
                        {
                            this.props.showSwitchStock && (
                                <>
                                    <label className="mr-2 mt-1 ml-auto">Stock con Comprobante</label>
                                    <label className="checkbox-togglePreventa">
                                        <div className="togglePreventa">
                                            <input
                                                type="checkbox"
                                                name="filtroTipoStock"
                                                className="toggle-state"
                                                onChange={this.onChangeOtherStock}
                                                checked={this.state.isStockGood}
                                                disabled={this.state.isLoading}
                                            />
                                            <div className="togglePreventa-inner">
                                                <div className="indicator" />
                                            </div>
                                            <div className="active-bg" />
                                        </div>
                                    </label>
                                </>
                            )
                        }
                    </div>
                </div>

                <div className="form-group encabezadoPV" style={{height: '65%'}}>

                    <div className="preventa__tipo--comprobante">

                    </div>

                    <ListaProductosPreventa
                        showDefaultColor={this.props.showDefaultColor}
                        stockKey={this.props.stockKey}
                        load={this.state.isLoading}
                        productos={this.state.prods}
                        combos={this.state.search == "" ? this.state.combos : []}
                        AddProductoInList={this.onClickItem}
                        checkedTipoStock={this.state.isStockGood}
                        onToggleDetail={this.onToggleDetail}
                    />

                    <button id={"BtnReloadProds"} hidden className="btn btn-primary" onClick={this.reloadProds}>
                        reload
                    </button>
                </div>

            </>
        );
    }
}
